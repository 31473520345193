import { DateTimeUnit } from 'luxon';
import { GTMEvent } from './google-tag-manager';

declare global {
    interface Window {
        dataLayer: GTMEvent[]; // For Google Tag Manager
        __mkEnv: PublicEnv; // For env variables
    }
}

type AWSEnvironment = 'dev' | 'pilot' | 'prod';
export type Environment = AWSEnvironment | 'bitbucket' | 'docker' | 'local' | undefined;

export type TimeUnit = 'month' | 'quarter' | 'year';
export enum TimeKeyFormat {
    month = 'yyyy-MM',
    quarter = 'yyyy-Qq',
    year = 'yyyy',
}

export interface MKProcessEnv {
    SECRETS_MANAGER_ENV: string;
}

export interface MKSecretsManagerEnv {
    ENVIRONMENT: Environment;
    MK_API_SERVER_EXTERNAL_URL: string;
    INSIGHT_URL: string;
    INSIGHT_URL_CHINA: string;
    SALESFORCE_COMMUNITY_URL: string;
    MYPARTS_URL: string;
    MYPARTS_INTERNAL_URL: string;
    GTM_ID: string;
}

export type MKProcessEnvKey = Extract<keyof MKProcessEnv, string>;
export type MKProcessEnvSecretsManagerEnvKey = Extract<keyof MKSecretsManagerEnv, string>;

export interface PublicEnv {
    apiBaseURL: string;
    insightURL: string;
    insightURLChina: string;
    salesforceCommunityURL: string;
    myPartsURL: string;
    myPartsInternalURL: string;
    gtmId: string;
}

export enum LoginState {
    Failed = 'failed',
    LoggedOut = 'logged-out',
    NotStarted = 'not-started',
    Successful = 'successful',
}

export enum LoadingState {
    NotLoaded = 'not-loaded',
    Loading = 'loading',
    Successful = 'successful',
    Failed = 'failed',
}

export enum Language {
    English = 'en',
    Spanish = 'es',
    German = 'de',
    French = 'fr',
    Swedish = 'sv',
    Chinese = 'zh',
    'Chinese (Simplified)' = 'zh',
}

export type UserLanguage = keyof typeof Language;

type EquipmentNumberFilter = string[] | null;
export type EquipmentShipToFilter = { [key: string]: EquipmentNumberFilter } | null;
export interface EquipmentFilter {
    [key: string]: EquipmentShipToFilter;
}

export type CustomerUserRole = 'Operator' | 'Manager' | 'Parts purchaser' | 'Dealer';

export type InternalUserRole = 'Admin';

export type UserRole = CustomerUserRole | InternalUserRole;

export type UserFlags = {
    cookieBannerShown: boolean;
    serviceMenuHelperShown: boolean;
};

interface SessionBaseUser {
    id: string;
    email?: string;
    name?: string;
    myParts: {
        active: boolean;
        canBuy: boolean;
    };
    flags: UserFlags;
}

export interface AccessRights {
    read: boolean;
    create: boolean;
}

export interface UserAccess {
    equipment: AccessRights;
    cases: AccessRights;
    maintenancePlans: AccessRights;
    maintenanceReporting: AccessRights;
    salesOrders: AccessRights;
    contacts: AccessRights;
    automationSupport: AccessRights;
    kalmarInsight: AccessRights;
    delivery: AccessRights;
    order: AccessRights;
    shippingDetails: AccessRights;
    dealerCommunity: AccessRights;
    mascusWidget: AccessRights;

}

export interface CustomerUser extends SessionBaseUser {
    contactId: string | null;
    equipmentFilter: EquipmentFilter;
    isAdmin: false;
    role: CustomerUserRole;
    access: UserAccess;
    sapCustomerNumber: string | null;
    language: UserLanguage;
    accountName: string;
}

export interface InternalUser extends SessionBaseUser {
    contactId: null;
    equipmentFilter: null;
    isAdmin: true;
    role: InternalUserRole;
}

export type SVGComponent = React.FC<React.SVGAttributes<SVGElement>>;

export type WidgetId =
    | 'automation-support'
    | 'contacts'
    | 'documents'
    | 'event-feed'
    | 'maintenance-reporting'
    | 'maintenance-schedule'
    | 'my-cases'
    | 'my-delivery'
    | 'my-equipment'
    | 'my-parts'
    | 'kalmar-insight'
    | 'mascus'
    | 'dealer-community';

interface WidgetBreadcrumbSubPage {
    icon: React.ReactNode;
    title: string;
}

export interface WidgetBreadcrumb {
    id: WidgetId;
    title: string;
    subPage?: WidgetBreadcrumbSubPage;
}

export interface Breadcrumb {
    title: string;
    pathname: string;
    key: string;
}

// Dates should be in DATE_KEY_FORMAT
export interface DateRange {
    startDate: string;
    endDate: string;
}

export interface KPIParams extends DateRange {
    splitBy?: DateTimeUnit;
    keyFormat?: TimeKeyFormat;
}

export interface InfiniteDateRange {
    startDate: string;
    endDate?: string;
}

export interface ISOTimeRange {
    startTime: string;
    endTime: string;
}

export interface InfiniteISOTimeRange {
    startTime: string;
    endTime?: string;
}

export interface InsightLicenseCounts {
    valid: number;
    expired: number;
    expiring: number;
}
