export const KALMAR_BLACK = '#000000';
export const KALMAR_GRAY_10 = '#E4E4E7';
export const KALMAR_GRAY_30 = '#B0B0B7';
export const KALMAR_GRAY_60 = '#70717D';
export const KALMAR_GRAY_80 = '#343A40';
export const KALMAR_GRAY = '#37424A';
export const KALMAR_RED = '#D52B1E';
export const KALMAR_WHITE = '#FFFFFF';
export const KALMAR_TURQUOISE = '#77C5D5';

export const EQUIPMENT_AVAILABILITY_BLUE = '#51A1BA';
export const EQUIPMENT_AVAILABILITY_DARK_BLUE = '#3B6584';

// New brand colors
export const KALMAR_PRIMARY_GRAPE = '#2C112D';
export const KALMAR_SECONDARY_PURPLE = '#BCADC8';
export const KALMAR_SECONDARY_CLAY = '#FEE8DB';
export const KALMAR_SECONDARY_SAND = '#E29682';
export const KALMAR_NEW_FOOTER = '#F8F9FA';
