import React from 'react';
import BondSingleSand from '../../assets/bond-single-sand-solid.svg';
import BondDoublePurple from '../../assets/bond-double-purple-solid.svg';
import BondDoubleGrapeExtended from '../../assets/bond-double extended-grape-solid.svg';
import css from './DealerCommunityWidget.module.scss';
import { useTranslation } from 'react-i18next';

export const DealerCommunityWidget: React.FC = () => {
    const { t } = useTranslation('DealerCommunity');

    return (
        <div>
            <ul className={css.customList}>
                <li>{t('Sales & general information')}</li>
                <li>{t('Marketing & products')}</li>
                <li>{t('Technical library')}</li>
                <li>{t('News')}</li>
                <li>{t('Kalmar contacts')}</li>
            </ul>
            <div className={css.ballContainer}>
                <BondSingleSand />
                <BondDoublePurple />
                <BondDoubleGrapeExtended />
            </div>
        </div>
    );
};
