import React, { useState } from 'react';
import css from './Transition.module.scss';

interface TransitionProps {
    animation?: string;
    durationMs?: number;
    easing?: string;
    htmlTagName?: keyof JSX.IntrinsicElements;
    className?: string;
    children: React.ReactNode;
}

export const Transition: React.FC<TransitionProps> = ({
    animation = css.fadeIn,
    durationMs = 250,
    easing = 'linear',
    htmlTagName = 'div',
    className,
    children,
}: TransitionProps) => {
    const [animated, setAnimated] = useState(false);
    const onAnimationEnd = () => {
        setAnimated(true);
    };

    const HtmlElement = htmlTagName;

    return (
        <HtmlElement
            className={className}
            style={!animated ? { animation: `${animation} ${durationMs}ms ${easing}` } : {}}
            onAnimationEnd={onAnimationEnd}
        >
            {children}
        </HtmlElement>
    );
};
