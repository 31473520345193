import React from 'react';
import { useTranslation } from 'react-i18next';
import EmailIcon from '../../assets/contact-icons/email.svg';
import PhoneIcon from '../../assets/contact-icons/phone.svg';
import { useOvermindState } from '../../state';
import { cns } from '../../utils';
import { Tooltip } from '../Tooltip/Tooltip';
import css from './ContactWidget.module.scss';

export const ContactWidget: React.FC = () => {
    const { t } = useTranslation('ContactWidget');
    const { contact } = useOvermindState();
    if (!contact) return null;

    return (
        <div>
            <p className={css.helpText}>{t('Need help?  Or need to know more about our services?')}</p>
            <p className={css.helpText}>{t('Contact us!')}</p>
            <div className={css.contact}>
                <EmailIcon />
                <div className={cns(css.details, css.bottomborder)}>
                    <div>
                        <div>{t('Email')}</div>
                        <Tooltip title={t<string>('Send email')} placement="bottom">
                            <a id="send-email-button" href={`mailto:${contact.email}`}>
                                {contact.email}
                            </a>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className={css.contact}>
                <PhoneIcon />
                <div className={css.details}>
                    <div>
                        <div>{t('Phone')}</div>
                        <Tooltip title={t<string>('Call the number')} placement="bottom">
                            <a id="call-phone-button" href={`tel:${contact.phone}`}>
                                {contact.phone}
                            </a>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};
