import AutomatedGuidedVehicle from './assets/equipment-icons/AGV-automated-guided-vehicle.svg';
import AutomaticStackingCrane from './assets/equipment-icons/ASC-automated-stacking-crane.svg';
import EmptyContainerHandler from './assets/equipment-icons/ECH-empty-container-handler.svg';
import ForkliftTruck from './assets/equipment-icons/FLT-forklift.svg';
import Logstacker from './assets/equipment-icons/LS-logstacker.svg';
import AutomaticRailMountedGantryCrane from './assets/equipment-icons/RMG-rail-mounted-gantry-automated.svg';
import RailMountedGantryCrane from './assets/equipment-icons/RMG-rail-mounted-gantry.svg';
import Reachstacker from './assets/equipment-icons/RS-reachstacker.svg';
import AutomaticRubberTyredGantryCrane from './assets/equipment-icons/RTG-rail-tyred-gantry-automated.svg';
import RubberTyredGantryCrane from './assets/equipment-icons/RTG-rail-tyred-gantry.svg';
import StraddleCarrier from './assets/equipment-icons/SC-straddle-carrier.svg';
import ShuttleCarrier from './assets/equipment-icons/SH-shuttle-carrier.svg';
import TerminalTractor from './assets/equipment-icons/TT-terminal-tractor.svg';

type IconComponent = typeof AutomatedGuidedVehicle;

const EQUIPMENT_ICONS_BY_TYPE: Record<string, IconComponent> = {
    'Automated Guided Vehicle': AutomatedGuidedVehicle,
    'Automatic Rail-Mounted Gantry Crane': AutomaticRailMountedGantryCrane,
    'Automatic Rubber Tyred Gantry Crane': AutomaticRubberTyredGantryCrane,
    'Automatic Stacking Crane': AutomaticStackingCrane,
    'Automated Shuttle Carrier': ShuttleCarrier,
    'Terminal Tractor': TerminalTractor,
    'Empty Container Handler': EmptyContainerHandler,
    'Forklift Truck': ForkliftTruck,
    Reachstacker: Reachstacker,
    Logstacker: Logstacker,
    'Rail-Mounted Gantry Crane': RailMountedGantryCrane,
    'Rubber Tyred Gantry Crane': RubberTyredGantryCrane,
    'Straddle Carrier': StraddleCarrier,
    'Shuttle Carrier': ShuttleCarrier,
};

export const getIconForEquipmentType = (type: string | null): IconComponent | null => {
    if (!type) return null;
    return EQUIPMENT_ICONS_BY_TYPE[type] || null;
};

export const hasIconForEquipmentType = (type: string | null): boolean => {
    if (!type) return false;
    return !!EQUIPMENT_ICONS_BY_TYPE[type];
};

