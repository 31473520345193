// overmind/index.ts
import { createOvermind, IAction, IConfig, IOnInitialize, IOperator } from 'overmind';
import {
    createActionsHook,
    createConnect,
    createEffectsHook,
    createHook,
    createReactionHook,
    createStateHook,
    IConnect,
} from 'overmind-react';
import * as actions from './actions';
import * as effects from './effects';
import { onInitialize } from './onInitialize';
import { state } from './state';

export const config = {
    onInitialize,
    state,
    actions,
    effects,
};

export const useOvermind = createHook<typeof config>();
export const useOvermindState = createStateHook<typeof config>();
export const useActions = createActionsHook<typeof config>();
export const useEffects = createEffectsHook<typeof config>();
export const useReaction = createReactionHook<typeof config>();
export const connectOvermind = createConnect<typeof config>();

export type Config = IConfig<{
    onInitialize: typeof config.onInitialize;
    state: typeof config.state;
    actions: typeof config.actions;
    effects: typeof config.effects;
}>;

export type OnInitialize = IOnInitialize<Config>;
export type Action<Input = void, Output = void> = IAction<Config, Input, Output>;
export type AsyncAction<Input = void, Output = void> = IAction<Config, Input, Promise<Output>>;
export type Operator<Input = void, Output = Input> = IOperator<Config, Input, Output>;
export type Connect = IConnect<typeof config>;

export const store = createOvermind(config);
