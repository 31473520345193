import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { API_DATE_FORMAT } from '../../../../interfaces/customer-api';
import { TimeKeyFormat } from '../../../../interfaces/internal';
import { KALMAR_PRIMARY_GRAPE, KALMAR_WHITE } from '../../../colors';
import { useActions, useOvermindState } from '../../../state';
import { cns, formatHoursAndMinutes, isFailed, isLoading, logger } from '../../../utils';
import { DurationTick, MonthAxisTick } from '../../ChartHelpers/ChartHelpers';
import { LoadingFailure } from '../../LoadingFailure/LoadingFailure';
import { Spinner } from '../../Spinner/Spinner';
import { TimeUnitSelector } from '../../TimeUnitSelector/TimeUnitSelector';
import css from './YearlyResponseTime.module.scss';

type YearlyResponseTimeProps = RouteComponentProps;

import { Formatter } from 'recharts/types/component/DefaultTooltipContent';

type TooltipFormatter = Formatter<string, string>;

const getTooltipFormatter = (t: TFunction): TooltipFormatter => {
    const formatter: TooltipFormatter = (value, name) => {
        const formattedVal = formatHoursAndMinutes(parseFloat(value));
        switch (name) {
            case 'average_minutes':
                return [formattedVal, t('Response time')];

            default:
                logger.error(`[YearlyResponseTime/tooltipFormatter]: Unknown name '${name}'`);
                return '';
        }
    };
    return formatter;
};

export const YearlyResponseTime: React.FC<YearlyResponseTimeProps> = () => {
    const { t, i18n } = useTranslation('ResponseTime');
    const { maintenanceReportingSelectedDate, selectedCustomerNumber, siteResponseTimeKPIsForYear, loadingStates } =
        useOvermindState();
    const { getResponseTimeKPI, handleMaintenanceReportingDateChange } = useActions();

    const startDate = maintenanceReportingSelectedDate.startOf('year').toFormat(API_DATE_FORMAT);
    const endDate = maintenanceReportingSelectedDate.endOf('year').toFormat(API_DATE_FORMAT);

    const loadResponseTimeForYear = () => getResponseTimeKPI({ startDate, endDate, splitBy: 'month' });
    useEffect(() => {
        if (!selectedCustomerNumber) return;

        const haveAllMonthsKPIs = siteResponseTimeKPIsForYear.length === 12;
        if (!haveAllMonthsKPIs) {
            loadResponseTimeForYear();
        }
    }, [maintenanceReportingSelectedDate, selectedCustomerNumber]);

    const data = siteResponseTimeKPIsForYear.map((kpi) => {
        return {
            month: DateTime.fromISO(kpi.startTime).setLocale(i18n.language).toFormat('MMMM'),
            ...kpi,
        };
    });

    const startMonthKey = maintenanceReportingSelectedDate.startOf('year').toFormat(TimeKeyFormat.month);
    const endMonthKey = maintenanceReportingSelectedDate.endOf('year').toFormat(TimeKeyFormat.month);

    const monthlyLoadingStates = Object.keys(loadingStates.responseTimeKPIs)
        .filter((dateKey) => dateKey >= startMonthKey && dateKey <= endMonthKey)
        .map((dateKey) => loadingStates.responseTimeKPIs[dateKey]);

    const isAnyMonthLoading = isLoading(monthlyLoadingStates);

    const noData = !isAnyMonthLoading && siteResponseTimeKPIsForYear.every((kpi) => kpi.average_minutes === null);

    if (isFailed(monthlyLoadingStates)) {
        return <LoadingFailure translationKey="Loading response time failed" retry={loadResponseTimeForYear} />;
    }

    return (
        <div className={css.yearlyResponseTime} id="yearly-response-time">
            <div>
                <div className={css.controls}>
                    <TimeUnitSelector
                        handleChange={handleMaintenanceReportingDateChange}
                        selectedDate={maintenanceReportingSelectedDate}
                        timeUnit="year"
                    />
                </div>
                <div className={cns(css.chartContainer, css.centered)}>
                    {isAnyMonthLoading ? (
                        <Spinner size={5} />
                    ) : !noData ? (
                        <ResponsiveContainer height={365} width="100%">
                            <AreaChart
                                data={data}
                                margin={{
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <XAxis
                                    dataKey="month"
                                    type="category"
                                    tick={<MonthAxisTick />}
                                    height={80}
                                    interval={0}
                                />
                                <YAxis width={80} tick={<DurationTick />} />
                                <Tooltip
                                    allowEscapeViewBox={{ x: true, y: true }}
                                    cursor={{ fill: 'transparent' }}
                                    formatter={getTooltipFormatter(t)}
                                    isAnimationActive={false}
                                />
                                <Area
                                    dataKey="average_minutes"
                                    stackId="1"
                                    stroke="none"
                                    fillOpacity={1}
                                    fill={KALMAR_PRIMARY_GRAPE}
                                    activeDot={{
                                        stroke: KALMAR_PRIMARY_GRAPE,
                                        strokeWidth: 2,
                                        fill: KALMAR_WHITE,
                                        r: 5,
                                    }}
                                    isAnimationActive={false}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    ) : (
                        <span className={css.noData}>{t('No data')}</span>
                    )}
                </div>
            </div>
        </div>
    );
};
