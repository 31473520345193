import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from 'react';
import {
    EQUIPMENT_AVAILABILITY_BLUE,
    EQUIPMENT_AVAILABILITY_DARK_BLUE,
    KALMAR_PRIMARY_GRAPE,
    KALMAR_GRAY_10,
    KALMAR_TURQUOISE,
} from '../../colors';

function getProgressBarColor(progress: number) {
    if (progress === 100) {
        return KALMAR_PRIMARY_GRAPE;
    }

    if (progress < 100 && progress > 95) {
        return EQUIPMENT_AVAILABILITY_DARK_BLUE;
    }

    if (progress < 95 && progress > 90) {
        return EQUIPMENT_AVAILABILITY_BLUE;
    }

    return KALMAR_TURQUOISE;
}

export interface ProgressBarProps {
    progress: number; // 0 to 100
    className?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, className }: ProgressBarProps) => {
    const CustomProgressBar = styled(LinearProgress)({
        backgroundColor: KALMAR_GRAY_10,
        height: 7,
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: getProgressBarColor(progress),
        },
    });

    return <CustomProgressBar className={className} value={progress} variant="determinate" />;
};
