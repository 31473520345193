import React from 'react';
import Close from '../../assets/close.svg';
import { cns } from '../../utils';

import css from './HelperPopup.module.scss';

export interface HelperPopupProps {
    text: string;
    onClose: () => void;
    className?: string;
    id?: string;
}

export const HelperPopup: React.FC<HelperPopupProps> = ({ text, onClose, className, id }: HelperPopupProps) => {
    return (
        <div className={cns(css.helperPopup, className)} id={id}>
            <div>{text}</div>
            <button className={css.closeButton} onClick={onClose}>
                <Close />
            </button>
        </div>
    );
};
