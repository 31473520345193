import capitalize from 'lodash/capitalize';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from '../../../interfaces/admin-api';
import Close from '../../assets/close.svg';
import Search from '../../assets/search.svg';
import { cns, logger, queryAdminAPI } from '../../utils';
import { Input } from '../Input/Input';
import { Spinner } from '../Spinner/Spinner';
import css from './CustomerSelector.module.scss';

interface CustomerSelectorProps {
    handleCustomerChange: (customer: Customer) => void;
    onClose: () => void;
}

export const CustomerSelector = React.forwardRef<HTMLDivElement, CustomerSelectorProps>(
    (props: CustomerSelectorProps, ref) => {
        const { handleCustomerChange, onClose } = props;
        const [customers, setCustomers] = useState<Customer[]>([]);
        const [isLoading, setIsLoading] = useState(false);
        const [isPristine, setIsPristine] = useState(true);
        const [searchString, setSearchString] = useState('');
        const { t } = useTranslation('CustomerSelector');
        const handleSearchStringChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                if (!isPristine) {
                    setIsPristine(true);
                }
                setSearchString(event.target.value);
            },
            [searchString, isPristine],
        );

        const getCustomers = useCallback(() => {
            setIsLoading(true);
            const query = queryString.stringify({ searchString });
            queryAdminAPI<Customer[]>(`/admin/customers?${query}`)
                .then((response) => {
                    if (response?.status === 200) {
                        setCustomers(response.results);
                    }
                })
                .catch((error) => {
                    // TODO: handle error
                    logger.log(error);
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsPristine(false);
                });
        }, [searchString]);

        const isSearchEnabled = !isLoading && searchString.length >= 3;
        const doSearch = () => {
            if (isSearchEnabled) {
                getCustomers();
            }
        };

        const handleSearchKeypress = useCallback(
            (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                    doSearch();
                }
            },
            [searchString],
        );
        const handleCustomerClick = useCallback((customer: Customer) => {
            handleCustomerChange(customer);
        }, []);
        const groupedCustomers = groupBy(customers, (customer) => customer.country);
        const countries = Object.keys(groupedCustomers).sort();
        return (
            <div id="customer-selector-modal" className={css.customerSelector} ref={ref} tabIndex={-1}>
                <header>
                    <h2>{t('Select customer')}</h2>
                    <Close onClick={onClose} />
                </header>
                <Input
                    icon={
                        isLoading ? (
                            <Spinner size={3} />
                        ) : (
                            <button onClick={doSearch} disabled={!isSearchEnabled} className={css.searchButton}>
                                <Search />
                            </button>
                        )
                    }
                    inputProps={{
                        disabled: isLoading,
                        onChange: handleSearchStringChange,
                        onKeyPress: handleSearchKeypress,
                        placeholder: `${t('Search with name, city, street address or sold-to number')}...`,
                        type: 'text',
                        value: searchString,
                    }}
                />
                {customers.length || isPristine ? (
                    <div className={css.countries}>
                        {countries.map((country) => {
                            const customersFromCountry = sortBy(groupedCustomers[country], (customer) => customer.name);
                            return (
                                <div key={country}>
                                    <h3>{capitalize(country)}</h3>
                                    <div className={css.customers}>
                                        {customersFromCountry.map((customer) => {
                                            const { city, name, postal_code, sold_to_number, street_address } =
                                                customer;
                                            return (
                                                <div
                                                    className={cns('customer-row', css.customerRow)}
                                                    key={sold_to_number}
                                                    onClick={() => handleCustomerClick(customer)}
                                                >
                                                    <div className={css.soldToNumber}>{sold_to_number}</div>
                                                    <div className={css.name}>{name}</div>
                                                    <div className={css.city}>{city}</div>
                                                    <div className={css.streetAddress}>
                                                        {street_address && <span>{street_address}</span>}
                                                        {street_address && postal_code && <span>, </span>}
                                                        {postal_code && <span>{postal_code}</span>}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p className={css.noResults}>
                        {t('No results found with')}: <strong>{searchString}</strong>
                    </p>
                )}
            </div>
        );
    },
);
