import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { ResponseTimeKPICallout } from '../../../../interfaces/customer-api';
import { TimeKeyFormat } from '../../../../interfaces/internal';
import { useActions, useOvermindState } from '../../../state';
import { formatHoursAndMinutes, isFailed } from '../../../utils';
import { LoadingFailure } from '../../LoadingFailure/LoadingFailure';
import { Spinner } from '../../Spinner/Spinner';
import { Column, Table } from '../../Table/Table';
import { TimeUnitSelector } from '../../TimeUnitSelector/TimeUnitSelector';
import css from './MonthlyResponseTime.module.scss';

type ResponseTimeProps = RouteComponentProps;

function getFormattedStartTime(translations: UseTranslationResponse<'ResponseTime'>, startTime: string) {
    const { i18n } = translations;
    return DateTime.fromISO(startTime).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_SHORT);
}

function getAverageChartLegend(selectedDate: DateTime, hasData: boolean, t: TFunction) {
    if (hasData) {
        return (
            <div className={css.averageChartLegend}>
                {t('avg. response time')}
                <span className={css.smallPrint}>{selectedDate.toFormat(`${t('in')} MMMM`)}</span>
            </div>
        );
    }
    return (
        <div className={css.averageChartLegend}>
            <span className={css.smallPrint}>{selectedDate.toFormat(`${t('in')} MMMM`)}</span>
        </div>
    );
}

export const MonthlyResponseTime: React.FC<ResponseTimeProps> = () => {
    const { isLoadingResponseTimeKPIs, loadingStates, maintenanceReportingSelectedDate, siteResponseTimeKPIs } =
        useOvermindState();
    const { handleMaintenanceReportingDateChange, getMaintenanceReportingKPIs } = useActions();

    const selectedMonthKey = maintenanceReportingSelectedDate.toFormat(TimeKeyFormat.month);
    const responseTime = siteResponseTimeKPIs[selectedMonthKey];

    const translations = useTranslation('ResponseTime');
    const { t, i18n } = translations;
    const selectedDate = maintenanceReportingSelectedDate.setLocale(i18n.language);
    const KPI = responseTime?.average_minutes || null;

    const columns: Column<ResponseTimeKPICallout>[] = [
        { title: t('Operation'), accessor: 'operation_description', className: css.operationText },
        {
            title: t('Call started'),
            accessor: (callout) => getFormattedStartTime(translations, callout.malfunction_start),
            className: css.malfunctionStart,
        },
        {
            title: t('Action started'),
            accessor: (callout) => getFormattedStartTime(translations, callout.operation_start),
            className: css.operationStart,
        },
        {
            title: t('Response time'),
            accessor: (callout) => formatHoursAndMinutes(callout.minutes),
            className: css.responseTimeMinutes,
        },
    ];

    if (isFailed(loadingStates.responseTimeKPIs[selectedMonthKey])) {
        return <LoadingFailure translationKey="Loading response time failed" retry={getMaintenanceReportingKPIs} />;
    }

    return (
        <div className={css.responseTime} id="monthly-response-time">
            <TimeUnitSelector
                handleChange={handleMaintenanceReportingDateChange}
                selectedDate={selectedDate}
                timeUnit="month"
            />
            <div className={css.averageChartOuterContainer}>
                <div className={css.averageChartInnerContainer}>
                    {isLoadingResponseTimeKPIs ? (
                        <Spinner size={5} />
                    ) : (
                        <>
                            <span>{KPI !== null ? formatHoursAndMinutes(KPI) : t('No data')}</span>
                            {getAverageChartLegend(selectedDate, KPI !== null, t)}
                        </>
                    )}
                </div>
            </div>
            <Table
                className={css.responseTimeTable}
                columns={columns}
                data={responseTime?.callouts || []}
                noRowsMessage={t('No data for this time period')}
                isLoading={isLoadingResponseTimeKPIs}
            />
        </div>
    );
};
