import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '../Link/Link';
import css from './TimeUnitLinks.module.scss';

interface TimeUnitLinksProps {
    links: {
        to: string;
        label: string;
    }[];
}

export const TimeUnitLinks: React.FunctionComponent<TimeUnitLinksProps> = ({ links }: TimeUnitLinksProps) => {
    const { t } = useTranslation('TimeUnitLinks');

    return (
        <div className={css.timeUnitLinks}>
            <span className={css.show}>{t('Show')}:</span>
            {links.map(({ to, label }) => (
                <Link id={label} to={to} activeClassName={css.isActive} key={to}>
                    {label}
                </Link>
            ))}
        </div>
    );
};
