import React from 'react';
import { LoadingState, SVGComponent, WidgetId } from '../../../interfaces/internal';
import { isFailed, isLoading, cns } from '../../utils';
import { Button, ButtonProps } from '../Button/Button';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import { LoadingFailure, LoadingFailureProps } from '../LoadingFailure/LoadingFailure';
import { Spinner } from '../Spinner/Spinner';
import css from './Widget.module.scss';
import { Tooltip } from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

interface HeaderButton {
    icon: SVGComponent;
    onClick?: (id: string) => void;
    linkTo?: string;
    buttonId?: string;
    tooltipName?: string;
}

interface HeaderBadge {
    content: string;
    onClick: () => void;
}

export interface WidgetProps {
    content: React.ReactNode;
    footerButtons?: ButtonProps[];
    headerButtons?: HeaderButton[];
    headerBadge?: HeaderBadge;
    id: WidgetId;
    title: string;
    loadingState?: LoadingState;
    failureProps?: LoadingFailureProps;
}

export const Widget: React.FunctionComponent<WidgetProps> = (props: WidgetProps) => {
    const { content, footerButtons, headerButtons, id, title, loadingState, failureProps, headerBadge } = props;
    const { t } = useTranslation('Tooltips');

    const isLoadingWidget = isLoading(loadingState);
    const loadingFailed = isFailed(loadingState);

    return (
        <div className={cns(css.widget, 'mk_widget')} id={`${id}-widget`}>
            <header>
                <div className={css.titleContainer}>
                    <h2>{title}</h2>
                    {headerBadge && (
                        <button className={css.badge} onClick={headerBadge.onClick}>
                            {headerBadge.content}
                        </button>
                    )}
                </div>

                {headerButtons && headerButtons.length && (
                    <div className={css.headerButtons}>
                        {headerButtons.map(({ icon, onClick, linkTo, buttonId, tooltipName }) => (
                            <Tooltip title={t(tooltipName || '')} placement="bottom" key={tooltipName}>
                                <div>
                                    <Button
                                        linkTo={linkTo}
                                        onClick={() => onClick && onClick(id)}
                                        variant="unstyled"
                                        id={buttonId}
                                    >
                                        {React.createElement(icon)}
                                    </Button>
                                </div>
                            </Tooltip>
                        ))}
                    </div>
                )}
            </header>
            {!loadingFailed && (
                <section>{isLoadingWidget ? <Spinner size={3} className={css.spinner} /> : content}</section>
            )}
            {loadingFailed && failureProps && (
                <section>
                    <LoadingFailure {...failureProps} />
                </section>
            )}
            {footerButtons && footerButtons.length && (
                <footer>
                    <ButtonGroup>
                        {footerButtons.map((buttonProps, index) => (
                            <Button key={`${id}-footer-button-${index}`} {...buttonProps} />
                        ))}
                    </ButtonGroup>
                </footer>
            )}
        </div>
    );
};
