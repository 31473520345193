import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeUnitLinks } from '../TimeUnitLinks/TimeUnitLinks';

interface PreventiveMaintenanceCompletionProps extends RouteComponentProps {
    children: React.ReactNode;
}

export const PreventiveMaintenanceCompletion: React.FC<PreventiveMaintenanceCompletionProps> = ({
    children,
}: PreventiveMaintenanceCompletionProps) => {
    const { t } = useTranslation('PreventiveMaintenanceCompletion');
    return (
        <div id="preventive-maintenance-completion">
            <TimeUnitLinks
                links={[
                    { to: 'monthly', label: t('Monthly report') },
                    { to: 'yearly', label: t('Yearly trend') },
                ]}
            />
            <div>{children}</div>
        </div>
    );
};
