import { ClickAwayListener, useMediaQuery } from '@mui/material';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from '../../../interfaces/admin-api';
import { Site } from '../../../interfaces/customer-api';
import { CustomerUser } from '../../../interfaces/internal';
import Checkmark from '../../assets/checkmark.svg';
import AccountCircle from '../../assets/account-circle.svg';
import MapHereIcon from '../../assets/map-here-icon.svg';
import ChevronRight from '../../assets/chevron-right.svg';
import { useOvermindState } from '../../state';
import { cns, getHostType, isCustomerUser } from '../../utils';
import css from './SiteSelector.module.scss';
import { BREAKPOINT_LARGE } from '../../config';

interface SiteSelectorProps {
    isCustomerSelectorVisible: boolean;
    openCustomerChangeModal: () => void;
    selectedSite: string | null;
    setSelectedSite: (site: string | null) => void;
    sites: Site[];
}

function getSelectedSiteText(
    t: TFunction,
    sites: Site[],
    selectedSite: string | null,
    shouldShowShipToNumber: boolean,
) {
    const siteObject = sites.find((site) => site.ship_to_number === selectedSite);
    if (siteObject) {
        const { city, name, ship_to_number } = siteObject;

        const nameAndCity = `${name}, ${city}`;

        if (!shouldShowShipToNumber) {
            return nameAndCity;
        }
        return (
            <>
                {nameAndCity}
                {shouldShowShipToNumber && <span className={css.shipTo}>({ship_to_number})</span>}
            </>
        );
    } else if (sites.length) {
        return t('Showing all sites');
    } else {
        return t('No sites with equipment');
    }
}

function getSelectedCustomerText(t: TFunction, selectedCustomer: Customer | null) {
    if (selectedCustomer) {
        const { name, sold_to_number } = selectedCustomer;
        return name ? `${name}, ${sold_to_number}` : sold_to_number;
    }
    return t('Select a customer');
}

export const SiteSelector: React.FC<SiteSelectorProps> = ({
    isCustomerSelectorVisible,
    openCustomerChangeModal,
    selectedSite,
    setSelectedSite,
    sites,
}: SiteSelectorProps) => {
    const [isSelectorVisible, setIsSelectorVisible] = useState(false);
    const { selectedCustomer, user } = useOvermindState();
    const { t } = useTranslation('SiteSelector');
    const isMobile = useMediaQuery(BREAKPOINT_LARGE);

    const handleToggle = (site: string) => () => {
        setIsSelectorVisible(false);
        if (selectedSite === site) {
            return setSelectedSite(null);
        }
        return setSelectedSite(site);
    };

    const handleShowAllSitesClick = () => {
        setIsSelectorVisible(false);
        setSelectedSite(null);
    };

    const exactlyOneSite = sites.length === 1;
    const isSiteSelectorEnabled = sites.length > 1;
    const noSapCustomerNumber = isCustomerUser(user) && user.sapCustomerNumber === null;

    // ShipTo is visible to everyone in non-production environments and for admins in production
    const shouldShowShipToNumber = user?.isAdmin || getHostType() !== 'production';

    const selectedSiteText = noSapCustomerNumber
        ? (user as CustomerUser)?.accountName
        : getSelectedSiteText(t, sites, selectedSite, shouldShowShipToNumber);

    const selectedCustomerText = getSelectedCustomerText(t, selectedCustomer);
    const containerClassName = isCustomerSelectorVisible
        ? cns(css.siteSelector, css.internalUser, isMobile && css.siteSelectorMobile)
        : css.siteSelector;

    return (
        <div className={containerClassName}>
            <div className={css.centeredContainer}>
                {isCustomerSelectorVisible && (
                    <div className={cns(css.dropdown, css.customerSelector)}>
                        <button id="customer-selector" className={css.dropdownButton} onClick={openCustomerChangeModal}>
                            <div>
                                <AccountCircle />
                            </div>
                            <span className={css.selectedText}>{selectedCustomerText}</span>
                            <ChevronRight className={css.chevron} />
                        </button>
                    </div>
                )}
                <div className={css.dropdown}>
                    <button
                        id="site-selector"
                        className={cns(
                            css.dropdownButton,
                            (exactlyOneSite || noSapCustomerNumber) && css.exactlyOneSite,
                        )}
                        disabled={!isSiteSelectorEnabled}
                        onClick={() => {
                            setIsSelectorVisible(true);
                        }}
                    >
                        <div className={css.mapIcon}>
                            <MapHereIcon />
                        </div>
                        <span className={css.selectedText}>{selectedSiteText}</span>
                        {isSiteSelectorEnabled && (
                            <ChevronRight className={isSelectorVisible ? css.flip : css.chevron} />
                        )}
                    </button>
                    {isSelectorVisible && (
                        <ClickAwayListener onClickAway={() => setIsSelectorVisible(false)}>
                            <ul>
                                <li className="site-list-item" id="show-all-sites" onClick={handleShowAllSitesClick}>
                                    <span className={css.showAllSites}>{t('Show all sites')}</span>
                                    {selectedSite === null && (
                                        <span className={css.selected}>
                                            <span>{t('Selected')}</span>
                                            <Checkmark />
                                        </span>
                                    )}
                                </li>
                                {sites.map(({ city, country, name, postal_code, ship_to_number, street_address }) => {
                                    return (
                                        <li
                                            className="site-list-item"
                                            key={ship_to_number}
                                            onClick={handleToggle(ship_to_number)}
                                        >
                                            <div className={css.siteHeader}>
                                                <span className={css.country}>{country}</span>
                                                <span className={css.divider}>&raquo;</span>
                                                <span className={css.city}>{city}</span>
                                            </div>
                                            <span className={css.address}>{`${street_address}, ${postal_code}`}</span>
                                            <span className={css.name}>
                                                {name}
                                                {shouldShowShipToNumber && (
                                                    <span className={css.shipTo}>({ship_to_number})</span>
                                                )}
                                            </span>

                                            {selectedSite === ship_to_number && (
                                                <span className={css.selected}>
                                                    <span>{t('Selected')}</span>
                                                    <Checkmark />
                                                </span>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </ClickAwayListener>
                    )}
                </div>
            </div>
        </div>
    );
};
