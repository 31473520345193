import React from 'react';
import SpinnerIcon from '../../assets/spinner.svg';
import { cns } from '../../utils';
import css from './Spinner.module.scss';

interface SpinnerProps {
    className?: string;
    size: number;
}

export const Spinner: React.FC<SpinnerProps> = ({ className, size }: SpinnerProps) => {
    const style = {
        height: `${size}rem`,
        width: `${size}rem`,
    };
    return <SpinnerIcon className={cns(css.spinner, className)} style={style} />;
};
