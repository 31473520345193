import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneIcon from '../../assets/contact-icons/phone-solid.svg';
import { Tooltip } from '../Tooltip/Tooltip';
import css from './AutomationSupportWidget.module.scss';

const contactGroups = [
    [
        {
            name: 'Global KalmarOne Automation System support',
            phone: '+61 2 8001 6208',
        },
    ],
    [
        {
            name: 'SmartPort Process Automation Support',
            phone: '+1 510.267.LIFT (5438)',
        },
        {
            name: 'SmartPort Process Automation - Weekend Support',
            phone: '+1 510.267.5585',
        },
    ],
    [
        {
            name: 'Australia - RTCS Support',
            phone: '1800 736 596',
        },
        {
            name: 'Australia - AVCS Support',
            phone: '1800 736 595',
        },
    ],
];

export const AutomationSupportWidget: React.FC = () => {
    const { t } = useTranslation('Common');

    return (
        <div>
            {contactGroups.map((contacts, index) => (
                <section className={css.section} key={index}>
                    {contacts.map(({ name, phone }) => (
                        <div className={css.contact} key={name}>
                            <div className={css.contactName}>{name}</div>
                            <div className={css.phone}>
                                <PhoneIcon />
                                <Tooltip title={t<string>('Call the number')} placement="bottom">
                                    <a id={`call-${name}-button`} href={`tel:${phone}`}>
                                        {phone}
                                    </a>
                                </Tooltip>
                            </div>
                        </div>
                    ))}
                </section>
            ))}
        </div>
    );
};
