import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeUnit } from '../../../interfaces/internal';
import ChevronLeft from '../../assets/chevron-left.svg';
import ChevronRight from '../../assets/chevron-right.svg';
import { cns } from '../../utils';
import css from './TimeUnitSelector.module.scss';

interface TimeUnitSelectorProps {
    className?: string;
    handleChange: (startDate: DateTime) => void;
    isDisabled?: boolean;
    selectedDate: DateTime;
    timeUnit: TimeUnit;
}

export const TimeUnitSelector: React.FC<TimeUnitSelectorProps> = (props: TimeUnitSelectorProps) => {
    const { className, handleChange, isDisabled, timeUnit } = props;
    const { i18n } = useTranslation('TimeUnitSelector');

    const selectedDate = props.selectedDate.setLocale(i18n.language);
    const previousDate = selectedDate.minus({ [timeUnit]: 1 }).startOf(timeUnit);
    const nextDate = selectedDate.plus({ [timeUnit]: 1 }).startOf(timeUnit);
    const goToPrevious = () => {
        handleChange(previousDate);
    };
    const goToNext = () => {
        handleChange(nextDate);
    };

    const longFormats = {
        month: 'MMMM yyyy',
        quarter: 'Qq yyyy',
        year: 'yyyy',
    };
    const longFormat = longFormats[timeUnit];

    const shortFormats = {
        month: 'MMMM',
        quarter: 'Qq',
        year: 'yyyy',
    };
    const shortFormat = shortFormats[timeUnit];

    const isNextInFuture = DateTime.local() < nextDate;

    return (
        <div className={cns(css.timeUnitSelector, className, 'time-unit-selector')}>
            <button
                id="date-selector-left-arrow"
                className={cns(css.button, css.leftButton)}
                onClick={goToPrevious}
                disabled={isDisabled}
            >
                <ChevronLeft />
                <span className={css.buttonText}>{selectedDate.minus({ [timeUnit]: 1 }).toFormat(shortFormat)}</span>
            </button>

            <span className={cns(css.selectedDate, css[timeUnit])}>{selectedDate.toFormat(longFormat)}</span>

            <button
                className={cns(css.button, css.rightButton)}
                onClick={goToNext}
                disabled={isDisabled || isNextInFuture}
                id="date-selector-right-arrow"
            >
                <span className={css.buttonText}>{selectedDate.plus({ [timeUnit]: 1 }).toFormat(shortFormat)}</span>
                <ChevronRight />
            </button>
        </div>
    );
};
