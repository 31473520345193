import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';
import { PreventiveMaintenanceCompletionKPI } from '../../../../interfaces/customer-api';
import { TimeKeyFormat } from '../../../../interfaces/internal';
import { KALMAR_PRIMARY_GRAPE, KALMAR_RED } from '../../../colors';
import { useActions, useOvermindState } from '../../../state';
import { isFailed, logger } from '../../../utils';
import { LoadingFailure } from '../../LoadingFailure/LoadingFailure';
import { Spinner } from '../../Spinner/Spinner';
import { TimeUnitSelector } from '../../TimeUnitSelector/TimeUnitSelector';
import css from './MonthlyPMCompletion.module.scss';

type MonthlyPMCompletionProps = RouteComponentProps;

interface BarChartData {
    /** Equipment generic category */
    category: string;
    /** Value to be used when rendering chart */
    notCompletedForChart: number;
    /**
     * Value to be used when rendering chart.
     *
     * If `notCompletedForChart` is the same as `plannedForChart`,
     * then we do not want to render the planned stack.
     */
    plannedForChart: number;
    /** Value to be shown in tooltip */
    plannedForTooltip: number;
}

interface PieChartData {
    count: number;
    description: 'completed' | 'not-completed';
    fill: string;
}

interface Group {
    label: string;
    completed: number;
    planned: number;
    percentage: number;
}

type TooltipFormatter = Formatter<number, string>;

const getTooltipFormatter = (t: TFunction): TooltipFormatter => {
    const formatter: TooltipFormatter = (barValue, name, props) => {
        switch (name) {
            case 'notCompletedForChart':
                return [barValue, t('Not completed')];
            case 'plannedForChart': {
                const tooltipValue = props.payload ? (props.payload.plannedForTooltip as number) : barValue;
                return [tooltipValue, t('Planned')];
            }
            default:
                logger.error(`[MonthlyPMCompletion/tooltipFormatter]: Unknown name '${name}'`);
                return NaN;
        }
    };
    return formatter;
};

function getGroups(t: TFunction, KPI: PreventiveMaintenanceCompletionKPI): Group[] {
    return Object.entries(KPI.byType).map(([type, values]) => {
        return {
            ...values,
            label: `${type}s`,
        };
    });
}

function getBarChartData(t: TFunction, KPI: PreventiveMaintenanceCompletionKPI): BarChartData[] {
    const groups = getGroups(t, KPI);

    return Object.values(groups).reduce<BarChartData[]>((previous, { planned, completed, label }) => {
        const notCompleted = planned - completed;
        return [
            ...previous,
            {
                category: label,
                notCompletedForChart: notCompleted,
                plannedForTooltip: planned,
                plannedForChart: planned === notCompleted ? 0 : planned,
            },
        ];
    }, []);
}

function getPieChartData(KPI: PreventiveMaintenanceCompletionKPI): PieChartData[] {
    return KPI.all.planned !== 0
        ? [
              {
                  description: 'completed',
                  count: KPI.all.completed,
                  fill: KALMAR_PRIMARY_GRAPE,
              },
              {
                  description: 'not-completed',
                  count: KPI.all.planned - KPI.all.completed,
                  fill: KALMAR_RED,
              },
          ]
        : [
              {
                  description: 'completed',
                  count: 1,
                  fill: KALMAR_PRIMARY_GRAPE,
              },
          ];
}

function getPieChartText(t: TFunction, KPI?: PreventiveMaintenanceCompletionKPI) {
    if (KPI) {
        const { percentage } = KPI.all;
        return <span className={css.pieChartText}>{`${(percentage * 100).toFixed(1)}%`}</span>;
    }
    return <span className={css.pieChartText}>{t('No data')}</span>;
}

function getPieChartLegend(t: TFunction, selectedDate: DateTime, KPI?: PreventiveMaintenanceCompletionKPI) {
    if (KPI) {
        const { completed, planned } = KPI.all;
        return (
            <div className={css.pieChartLegend}>
                <strong>{`${completed} / ${planned}`}</strong> {t('tasks')}
                <span className={css.completedInText}>{selectedDate.toFormat(`'${t('completed in')}' MMMM`)}</span>
            </div>
        );
    }
    return (
        <div className={css.pieChartLegend}>
            <span>{selectedDate.toFormat(`'${t('No data for')}' MMMM`)}</span>
        </div>
    );
}

export const MonthlyPMCompletion: React.FC<MonthlyPMCompletionProps> = () => {
    const { isLoadingPMCompletionKPIs, loadingStates, maintenanceReportingSelectedDate, sitePMCompletionKPIs } =
        useOvermindState();
    const { handleMaintenanceReportingDateChange, getMaintenanceReportingKPIs } = useActions();

    const selectedMonthKey = maintenanceReportingSelectedDate.toFormat(TimeKeyFormat.month);
    const PMCompletion = sitePMCompletionKPIs[selectedMonthKey];

    const { t, i18n } = useTranslation('PreventiveMaintenanceCompletion');
    const selectedDate = maintenanceReportingSelectedDate.setLocale(i18n.language);

    let pieChartData: PieChartData[] = [];
    let barChartData: BarChartData[] = [];

    if (PMCompletion) {
        pieChartData = getPieChartData(PMCompletion);
        barChartData = getBarChartData(t, PMCompletion);
    }

    if (isFailed(loadingStates.PMCompletionKPIs[selectedMonthKey])) {
        return (
            <LoadingFailure
                translationKey="Loading preventive maintenance data failed"
                retry={getMaintenanceReportingKPIs}
            />
        );
    }

    return (
        <div className={css.monthlyPmCompletion} id="montly-preventive-maintenance-completion">
            <TimeUnitSelector
                handleChange={handleMaintenanceReportingDateChange}
                selectedDate={selectedDate}
                timeUnit="month"
            />
            <div className={css.charts}>
                <div className={css.pieChartOuterContainer}>
                    <div className={css.pieChartInnerContainer}>
                        {isLoadingPMCompletionKPIs ? (
                            <Spinner size={5} />
                        ) : (
                            <>
                                <ResponsiveContainer className={css.pieChart} height={365} width="100%">
                                    <PieChart>
                                        <Pie
                                            data={pieChartData}
                                            dataKey="count"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={90}
                                            outerRadius={105}
                                            startAngle={90}
                                            endAngle={450}
                                            blendStroke={true}
                                            isAnimationActive={false}
                                        />
                                    </PieChart>
                                </ResponsiveContainer>
                                {getPieChartText(t, PMCompletion)}
                                {getPieChartLegend(t, selectedDate, PMCompletion)}
                            </>
                        )}
                    </div>
                </div>
                <div className={css.stackedBarChartContainer}>
                    <ResponsiveContainer className={css.centered} height={365} width="100%">
                        {isLoadingPMCompletionKPIs ? (
                            <Spinner size={5} />
                        ) : barChartData.length ? (
                            <BarChart
                                data={barChartData}
                                margin={{
                                    top: 115,
                                    bottom: 20,
                                    left: 60,
                                    right: 60,
                                }}
                            >
                                <XAxis axisLine={false} dataKey="category" dy={10} tickLine={false} />
                                <Tooltip
                                    allowEscapeViewBox={{ x: true, y: true }}
                                    cursor={{ fill: 'transparent' }}
                                    formatter={getTooltipFormatter(t)}
                                    isAnimationActive={false}
                                    labelFormatter={() => ''}
                                />
                                <Bar barSize={40} dataKey="plannedForChart" stackId="a" fill={KALMAR_PRIMARY_GRAPE} />
                                <Bar barSize={40} dataKey="notCompletedForChart" stackId="a" fill={KALMAR_RED} />
                            </BarChart>
                        ) : (
                            <span className={css.noData}>{selectedDate.toFormat(`'${t('No data for')}' MMMM`)}</span>
                        )}
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};
