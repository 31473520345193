import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language } from '../interfaces/internal';
import en from './translations/en';

const DEFAULT_TRANSLATION_NAMESPACE = 'App';
const SUPPORTED_LANGUAGES = Object.values(Language);

i18n.use(initReactI18next).init({
    debug: window.location.hostname === 'localhost' ? true : false,
    fallbackLng: ['en'],
    fallbackNS: 'Common',
    defaultNS: DEFAULT_TRANSLATION_NAMESPACE,
    interpolation: {
        escapeValue: false,
    },
    keySeparator: false,
    lng: 'en',
    resources: { en },
    supportedLngs: SUPPORTED_LANGUAGES,
    returnNull: false,
});

export default i18n;
