import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeUnitLinks } from '../TimeUnitLinks/TimeUnitLinks';

interface ResponseTimeProps extends RouteComponentProps {
    children: React.ReactNode;
}

export const ResponseTime: React.FC<ResponseTimeProps> = ({ children }: ResponseTimeProps) => {
    const { t } = useTranslation('ResponseTime');
    return (
        <div id="response-time">
            <TimeUnitLinks
                links={[
                    { to: 'monthly', label: t('Monthly report') },
                    { to: 'yearly', label: t('Yearly trend') },
                ]}
            />
            <div>{children}</div>
        </div>
    );
};
