import { navigate } from '@reach/router';
import React, { useCallback } from 'react';
import { WidgetBreadcrumb } from '../../../interfaces/internal';
import Home from '../../assets/home.svg';
import { cns } from '../../utils';
import { SubPageContainer } from '../SubPageContainer/SubPageContainer';
import css from './SubPage.module.scss';
import { t } from 'i18next';
import { Link } from '../Link/Link';
import { useMediaQuery } from '@mui/material';
import { BREAKPOINT_SMALL } from '../../config';

interface SubPageProps {
    breadcrumb: WidgetBreadcrumb;
    onCloseClick?: () => void;
    children: React.ReactNode;
    idSlug: string;
}

function createWidgetBreadcrumbs(breadcrumb: WidgetBreadcrumb, onCloseClick: SubPageProps['onCloseClick']) {
    const { title, subPage } = breadcrumb;

    return (
        <div className={css.breadcrumb}>
            {subPage ? (
                <span className={css.titleContainer}>
                    <span
                        id="breadcrumb-previous-page"
                        className={cns(css.title, css.clickable, css.hover)}
                        onClick={onCloseClick}
                    >
                        {title}
                    </span>
                    <span className={css.separator}>/</span>
                    <span id="breadcrumb-current-page" className={css.subTitle}>
                        {subPage.title}
                    </span>
                </span>
            ) : (
                <span id="breadcrumb-current-page" className={css.subTitle}>
                    {title}
                </span>
            )}
        </div>
    );
}

export const SubPage: React.FC<SubPageProps> = ({ breadcrumb, children, onCloseClick, idSlug }: SubPageProps) => {
    const isMobile = useMediaQuery(BREAKPOINT_SMALL);

    const handleCloseClick = useCallback(async () => {
        if (onCloseClick) {
            onCloseClick();
        } else {
            await navigate('/');
        }
    }, []);

    const pageTitle = breadcrumb.subPage?.title || breadcrumb.title;
    return (
        <>
            <header className={css.header}>
                <div className={css.closeButton}>
                    <Link id="close-subpage-button" to="/">
                        <Home id="close-subpage-button-icon" />
                        {!isMobile && (
                            <>
                                <span className={css.homepageText}>{t('MyKalmar homepage')}</span>
                            </>
                        )}
                        <span className={css.separator}>/</span>
                    </Link>
                    {createWidgetBreadcrumbs(breadcrumb, handleCloseClick)}
                </div>
            </header>
            <SubPageContainer className={css.subPageContainer} idSlug={idSlug}>
                <div className={css.pageTitle}>{pageTitle}</div>
                <section>{children}</section>
            </SubPageContainer>
        </>
    );
};
