import React from 'react';
import { cns } from '../../utils';
import css from './WidgetSubHeading.module.scss';

interface WidgetSubHeadingProps {
    title: string;
    className?: string;
}

export const WidgetSubHeading: React.FC<WidgetSubHeadingProps> = ({ title, className }: WidgetSubHeadingProps) => {
    return (
        <div className={cns(css.widgetSubHeading, className)}>
            <h3>{title}</h3>
        </div>
    );
};
