import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { BREAKPOINT_SMALL } from '../../config';

export interface WithIsMobileProps {
    isMobile?: boolean;
}

export function withIsMobile<P>(Component: React.ComponentType<P>) {
    return function WrappedComponent(props: P): JSX.Element {
        const matches = useMediaQuery(BREAKPOINT_SMALL);

        return <Component isMobile={matches} {...props} />;
    };
}
