import useMediaQuery from '@mui/material/useMediaQuery';
import { navigate, RouteComponentProps } from '@reach/router';
import compact from 'lodash/compact';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API_DATE_FORMAT, EquipmentAvailabilityKPI } from '../../../interfaces/customer-api';
import { TimeKeyFormat, TimeUnit } from '../../../interfaces/internal';
import ArrowRight from '../../assets/arrow-right.svg';
import { BREAKPOINT_MEDIUM } from '../../config';
import { getIconForEquipmentType } from '../../equipment-icons';
import { useActions, useOvermindState } from '../../state';
import { getShortEquipmentType, isFailed, isLoading, isSuccessful } from '../../utils';
import { Button } from '../Button/Button';
import { LoadingFailure } from '../LoadingFailure/LoadingFailure';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { Column, Table } from '../Table/Table';
import { TimeUnitLinks } from '../TimeUnitLinks/TimeUnitLinks';
import { TimeUnitSelector } from '../TimeUnitSelector/TimeUnitSelector';
import css from './EquipmentAvailability.module.scss';

interface EquipmentAvailabilityProps extends RouteComponentProps {
    uri?: string; // Set by router
    timeUnit?: TimeUnit; // URL parameters set by router
}

interface EquipmentGroup {
    availability: number;
    icon: React.FC<React.SVGAttributes<SVGElement>> | null;
    label: string;
}

function getGroups(current?: EquipmentAvailabilityKPI): EquipmentGroup[] {
    if (!current || !current.byType) {
        return [];
    }

    return Object.entries(current.byType).map(([type, availability]) => {
        return {
            availability: availability?.average ? availability.average * 100 : 0,
            icon: getIconForEquipmentType(type),
            label: type,
        };
    });
}

export const EquipmentAvailability: React.FC<EquipmentAvailabilityProps> = (props: EquipmentAvailabilityProps) => {
    const { loadingStates, maintenanceReportingSelectedDate, siteEquipmentAvailabilityKPIs } = useOvermindState();
    const { handleMaintenanceReportingDateChange, getEquipmentAvailabilityKPI } = useActions();
    const { t } = useTranslation('EquipmentAvailability');
    const isMobile = useMediaQuery(BREAKPOINT_MEDIUM);

    const { timeUnit } = props;

    const validTimeUnits = ['month', 'quarter', 'year'];
    if (timeUnit === undefined || !validTimeUnits.includes(timeUnit)) {
        navigate('month', { replace: true });
        return null;
    }

    const keyFormat = TimeKeyFormat[timeUnit];

    const selectedTimeUnitKey = maintenanceReportingSelectedDate.toFormat(keyFormat);

    const selectedAvailability = siteEquipmentAvailabilityKPIs[selectedTimeUnitKey];

    const isSelectedLoading = isLoading(loadingStates.equipmentAvailabilityKPIs[selectedTimeUnitKey]);
    const isSelectedFailed = isFailed(loadingStates.equipmentAvailabilityKPIs[selectedTimeUnitKey]);
    const isSelectedSuccesful = isSuccessful(loadingStates.equipmentAvailabilityKPIs[selectedTimeUnitKey]);

    const getSelectedAvailability = async () => {
        if (!isSelectedLoading && !isSelectedSuccesful) {
            getEquipmentAvailabilityKPI({
                startDate: maintenanceReportingSelectedDate.startOf(timeUnit).toFormat(API_DATE_FORMAT),
                endDate: maintenanceReportingSelectedDate.endOf(timeUnit).toFormat(API_DATE_FORMAT),
                keyFormat,
            });
        }
    };

    if (isSelectedFailed) {
        return (
            <LoadingFailure translationKey="Loading equipment availability failed" retry={getSelectedAvailability} />
        );
    }

    getSelectedAvailability();

    const groups = getGroups(selectedAvailability);

    const columns: Column<EquipmentGroup>[] = compact([
        {
            title: isMobile ? t('Type') : '',
            accessor: (group: EquipmentGroup) => {
                return (
                    <>
                        {group.icon ? React.createElement(group.icon) : null}
                        {isMobile && <div>{getShortEquipmentType(group.label)}</div>}
                    </>
                );
            },
            className: css.icon,
        },
        !isMobile
            ? {
                  title: t('Equipment type'),
                  accessor: 'label',
                  className: css.type,
              }
            : undefined,
        {
            title: t('Availability'),
            accessor: (group: EquipmentGroup) => (
                <div className={css.availabilityContainer}>
                    {!isMobile && <ProgressBar progress={group.availability} className={css.progressBar} />}
                    <span className={css.percentage}>{`${group.availability.toFixed(2)}%`}</span>
                </div>
            ),
            className: css.availability,
        },
        {
            title: '',
            accessor: ({ label }: EquipmentGroup) => (
                <Button
                    id={`see-${label}-details-button`}
                    variant="link"
                    linkTo={`${props.uri}/${label}`}
                    className={css.seeDetailsButton}
                >
                    {t('See details')} <ArrowRight />
                </Button>
            ),
            className: css.seeDetails,
        },
    ]);

    const isLoadingKPIs = isLoading(loadingStates.equipmentAvailabilityKPIs[selectedTimeUnitKey]);

    return (
        <div id="equipment-availability">
            <TimeUnitLinks
                links={[
                    { to: '../month', label: t('Month') },
                    { to: '../quarter', label: t('Quarter') },
                    { to: '../year', label: t('Year') },
                ]}
            />
            <div className={css.center}>
                <TimeUnitSelector
                    handleChange={handleMaintenanceReportingDateChange}
                    selectedDate={maintenanceReportingSelectedDate}
                    timeUnit={timeUnit}
                />
            </div>
            <div className={css.equipmentAvailability}>
                <Table
                    columns={columns}
                    data={groups}
                    noRowsMessage={t('No data for this time period')}
                    className={css.equipmentAvailabilityTable}
                    isLoading={isLoadingKPIs}
                />
            </div>
        </div>
    );
};
