import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { UseTranslationResponse, useTranslation } from 'react-i18next';
import { SalesOrder, SalesOrderStatus } from '../../../interfaces/customer-api';
import Triangle from '../../assets/arrow-dropdown.svg';
import OrderDeliveredIcon from '../../assets/order-status-icons/order-delivered.svg';
import OrderInWareHouseProcessIcon from '../../assets/order-status-icons/order-in-warehouse-process.svg';
import OrderNotConfirmedIcon from '../../assets/order-status-icons/order-not-confirmed.svg';
import OrderOpenIcon from '../../assets/order-status-icons/order-open.svg';
import OrderShippedIcon from '../../assets/order-status-icons/order-shipped.svg';
import { useOvermindState } from '../../state';
import { cns, formatDate, mkEnv } from '../../utils';
import { Tooltip } from '../Tooltip/Tooltip';
import css from './MyPartsWidget.module.scss';

const getStatusIcon = (status: SalesOrderStatus) => {
    switch (status) {
        case 'NOT CONFIRMED':
            return <OrderNotConfirmedIcon />;
        case 'OPEN':
            return <OrderOpenIcon />;
        case 'IN WAREHOUSE PROCESS':
            return <OrderInWareHouseProcessIcon />;
        case 'SHIPPED':
            return <OrderShippedIcon />;
        case 'DELIVERED':
            return <OrderDeliveredIcon />;
        default:
            return null;
    }
};

const getStatusTranslationKey = (status: SalesOrderStatus) => {
    switch (status) {
        case 'NOT CONFIRMED':
            return 'Processing';
        case 'OPEN':
            return 'Order received';
        case 'IN WAREHOUSE PROCESS':
            return 'At warehouse';
        case 'SHIPPED':
            return 'Shipped';
        case 'DELIVERED':
            return 'Delivered';
        default:
            return '';
    }
};

interface OrderStatusProps {
    status: SalesOrder['status'];
    translationResponse: UseTranslationResponse<'MyPartsWidget'>;
}

const OrderStatus: React.FC<OrderStatusProps> = ({ status, translationResponse }: OrderStatusProps) => {
    const { t } = translationResponse;
    const statuses: SalesOrder['status'][] = ['NOT CONFIRMED', 'OPEN', 'IN WAREHOUSE PROCESS', 'SHIPPED', 'DELIVERED'];

    return (
        <div className={css.orderStatus}>
            {statuses.map((s, index) => {
                const isLast = index === statuses.length - 1;
                const statusText = t(getStatusTranslationKey(s));
                return (
                    <Fragment key={s}>
                        <Tooltip
                            title={statusText}
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -16],
                                        },
                                    },
                                ],
                            }}
                            placement="bottom"
                        >
                            <div className={cns(css.orderStatusStep, s === status && css.activeStep)}>
                                <div className={css.orderStatusIcon}>{getStatusIcon(s)}</div>
                                <p className={css.orderStatusText}>{statusText}</p>
                            </div>
                        </Tooltip>
                        {!isLast && <div className={css.orderStatusLine} />}
                    </Fragment>
                );
            })}
        </div>
    );
};

interface OrderProps {
    order: SalesOrder;
    translationResponse: UseTranslationResponse<'MyPartsWidget'>;
    canNavigateToMyParts: boolean;
}

const Order: React.FC<OrderProps> = ({ order, translationResponse, canNavigateToMyParts }: OrderProps) => {
    const { t } = translationResponse;
    const date = DateTime.fromFormat(order.documentDate, 'yyyy-MM-dd');
    const dateString = date.isValid ? formatDate(date) : order.documentDate;

    const [isOpen, setIsOpen] = useState(false);

    const myPartsOrderURL = `${mkEnv.myPartsURL}/orders;go=${encodeURIComponent(order.globalOrderNumber)}`;

    const hasMultipleShipments = order.rows.length > 1;

    const getShipmentText = (
        status: string,
        trackingUrl: string,
        index: number,
        estimatedDate: string,
        actualDate: string,
    ) => {
        return (
            <>
                <span>
                    {t('Shipment')} {index + 1}:{' '}
                </span>
                {['NOT CONFIRMED', 'OPEN', 'IN WAREHOUSE PROCESS', 'CANCELLED'].includes(status) ? (
                    'Please wait for tracking information...'
                ) : status === 'SHIPPED' ? (
                    <a className={css.link} href={trackingUrl} target="_blank" rel="noopener noreferrer">
                        {t('Est. Delivery')} {formatDate(DateTime.fromISO(estimatedDate))}
                    </a>
                ) : (
                    <a className={css.link} href={trackingUrl} target="_blank" rel="noopener noreferrer">
                        {t('Delivered')} {formatDate(DateTime.fromISO(actualDate))}
                    </a>
                )}
            </>
        );
    };
    return (
        <li className={css.order} onClick={() => setIsOpen((prev) => !prev)}>
            <div className={css.customerPoAndDateRow}>
                <h3 className={css.customerPo}>
                    {t('Customer PO')}: <strong>{order.purchaseOrderNumber || t('N/A')}</strong>
                </h3>
                <div className={css.orderDate}>{dateString}</div>
            </div>
            <div className={css.orderRow}>
                <span className={css.label}>{t('Order number')}: </span>
                {canNavigateToMyParts ? (
                    <a href={myPartsOrderURL} className={css.link}>
                        {order.globalOrderNumber}
                    </a>
                ) : (
                    order.globalOrderNumber
                )}
                <div className={css.accordionRow}>
                    {isOpen ? t('Hide tracking') : t('View tracking')}
                    <Triangle className={cns(css.arrow, isOpen && css.arrowOpen)} />
                </div>
            </div>
            <div className={cns(css.tracking, isOpen && css.trackingOpen)} onClick={(e) => e.stopPropagation()}>
                <div className={css.trackingContent}>
                    <h5>{t('Tracking')}</h5>
                    {hasMultipleShipments && <p>{t('Note: this order will be delivered in multiple shipments.')}</p>}
                    <ul className={css.trackingLinks}>
                        {order.rows.map(
                            ({ status, trackingUrl, estimatedDeliveryDate, proofOfDeliveryDate }, index) => (
                                <li key={status}>
                                    {getShipmentText(
                                        status,
                                        trackingUrl,
                                        index,
                                        estimatedDeliveryDate,
                                        proofOfDeliveryDate,
                                    )}
                                </li>
                            ),
                        )}
                    </ul>
                </div>
            </div>
            <OrderStatus status={order.status} translationResponse={translationResponse} />
        </li>
    );
};

export const MyPartsWidget: React.FC = () => {
    const translationResponse = useTranslation('MyPartsWidget');
    const { salesOrders, canNavigateToMyParts } = useOvermindState();
    const { t } = translationResponse;

    return (
        <div className={css.myPartsWidget}>
            {salesOrders.length > 0 ? (
                <ul className={css.orders}>
                    {salesOrders.map((order, index) => (
                        <Order
                            order={order}
                            translationResponse={translationResponse}
                            canNavigateToMyParts={canNavigateToMyParts}
                            key={index}
                        />
                    ))}
                </ul>
            ) : (
                <p>{t('No orders')}</p>
            )}
        </div>
    );
};
