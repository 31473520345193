import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';
import { API_DATE_FORMAT } from '../../../../interfaces/customer-api';
import { TimeKeyFormat } from '../../../../interfaces/internal';
import { KALMAR_PRIMARY_GRAPE, KALMAR_RED, KALMAR_WHITE } from '../../../colors';
import { useActions, useOvermindState } from '../../../state';
import { cns, isFailed, isLoading, logger } from '../../../utils';
import { Button } from '../../Button/Button';
import { BasicYTick, MonthAxisTick, PercentageTick } from '../../ChartHelpers/ChartHelpers';
import { LoadingFailure } from '../../LoadingFailure/LoadingFailure';
import { Spinner } from '../../Spinner/Spinner';
import { TimeUnitSelector } from '../../TimeUnitSelector/TimeUnitSelector';
import css from './YearlyPMCompletion.module.scss';

type YearlyPMCompletionProps = RouteComponentProps;

type TooltipFormatter = Formatter<string, string>;

const getTooltipFormatter = (t: TFunction, isPercentage: boolean): TooltipFormatter => {
    const formatter: TooltipFormatter = (value, name) => {
        const formattedVal = isPercentage ? `${parseFloat(value).toFixed(1)}%` : value;
        switch (name) {
            case 'notCompletedForChart':
                return [formattedVal, t('Not completed')];
            case 'completedForChart': {
                return [formattedVal, t('Completed')];
            }
            default:
                logger.error(`[YearlyPMCompletion/tooltipFormatter]: Unknown name '${name}'`);
                return '';
        }
    };
    return formatter;
};

export const YearlyPMCompletion: React.FC<YearlyPMCompletionProps> = () => {
    const { t, i18n } = useTranslation('PreventiveMaintenanceCompletion');
    const { maintenanceReportingSelectedDate, selectedCustomerNumber, sitePMCompletionKPIsForYear, loadingStates } =
        useOvermindState();
    const { getPMCompletionKPI, handleMaintenanceReportingDateChange } = useActions();

    const [isPercentage, setIsPercentage] = useState(false);

    const startDate = maintenanceReportingSelectedDate.startOf('year').toFormat(API_DATE_FORMAT);
    const endDate = maintenanceReportingSelectedDate.endOf('year').toFormat(API_DATE_FORMAT);

    const loadPMCompletionForYear = () => getPMCompletionKPI({ startDate, endDate, splitBy: 'month' });
    useEffect(() => {
        if (!selectedCustomerNumber) return;

        const haveAllMonthsKPIs = sitePMCompletionKPIsForYear.length === 12;
        if (!haveAllMonthsKPIs) {
            loadPMCompletionForYear();
        }
    }, [maintenanceReportingSelectedDate, selectedCustomerNumber]);

    const resolveValue = (numerator: number, denominator: number) => {
        if (!isPercentage) return numerator;
        if (denominator === 0) return 0;

        return 100 * (numerator / denominator);
    };

    const data = sitePMCompletionKPIsForYear.map((kpi) => {
        return {
            month: DateTime.fromISO(kpi.startTime).setLocale(i18n.language).toFormat('MMMM'),
            ...kpi,
            completedForChart: resolveValue(kpi.all.completed, kpi.all.planned),
            notCompletedForChart: resolveValue(kpi.all.planned - kpi.all.completed, kpi.all.planned),
        };
    });

    const startMonthKey = maintenanceReportingSelectedDate.startOf('year').toFormat(TimeKeyFormat.month);
    const endMonthkey = maintenanceReportingSelectedDate.endOf('year').toFormat(TimeKeyFormat.month);

    const monthlyLoadingStates = Object.keys(loadingStates.PMCompletionKPIs)
        .filter((dateKey) => dateKey >= startMonthKey && dateKey <= endMonthkey)
        .map((dateKey) => loadingStates.PMCompletionKPIs[dateKey]);
    const isAnyMonthLoading = isLoading(monthlyLoadingStates);

    const nothingPlanned = !isAnyMonthLoading && sitePMCompletionKPIsForYear.every((kpi) => kpi.all.planned === 0);

    if (isFailed(monthlyLoadingStates)) {
        return (
            <LoadingFailure
                translationKey="Loading preventive maintenance data failed"
                retry={loadPMCompletionForYear}
            />
        );
    }

    return (
        <div className={css.yearlyPmCompletion} id="yearly-preventive-maintenance-completion">
            <div>
                <div className={css.centered}>
                    <TimeUnitSelector
                        handleChange={handleMaintenanceReportingDateChange}
                        selectedDate={maintenanceReportingSelectedDate}
                        timeUnit="year"
                    />
                </div>
                <div className={cns(css.chartContainer, css.centered)}>
                    {isAnyMonthLoading ? (
                        <Spinner size={5} />
                    ) : !nothingPlanned ? (
                        <ResponsiveContainer height={365} width="100%">
                            <AreaChart
                                data={data}
                                margin={{
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <XAxis
                                    dataKey="month"
                                    type="category"
                                    tick={<MonthAxisTick />}
                                    height={80}
                                    interval={0}
                                />
                                <YAxis
                                    width={80}
                                    domain={isPercentage ? [0, 100] : ['dataMin', 'dataMax + 10']}
                                    tick={isPercentage ? <PercentageTick /> : <BasicYTick />}
                                />
                                <Tooltip
                                    allowEscapeViewBox={{ x: true, y: true }}
                                    cursor={{ fill: 'transparent' }}
                                    formatter={getTooltipFormatter(t, isPercentage)}
                                    isAnimationActive={false}
                                    itemSorter={(payload) => (payload.dataKey === 'completedForChart' ? 0 : 1)}
                                />
                                <Area
                                    dataKey="notCompletedForChart"
                                    stackId="1"
                                    stroke="none"
                                    fillOpacity={1}
                                    fill={KALMAR_PRIMARY_GRAPE}
                                    activeDot={{
                                        stroke: KALMAR_PRIMARY_GRAPE,
                                        strokeWidth: 2,
                                        fill: KALMAR_WHITE,
                                        r: 5,
                                    }}
                                    isAnimationActive={false}
                                />
                                <Area
                                    dataKey="completedForChart"
                                    stackId="1"
                                    stroke="none"
                                    fillOpacity={1}
                                    fill={KALMAR_RED}
                                    activeDot={{ stroke: KALMAR_RED, strokeWidth: 2, fill: KALMAR_WHITE, r: 5 }}
                                    isAnimationActive={false}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    ) : (
                        <span className={css.noData}>{t('No planned maintenance')}</span>
                    )}
                </div>
            </div>
            <div className={css.controls}>
                <div className={css.percentageToggle}>
                    <Button
                        variant="link"
                        className={cns(!isPercentage && css.isActive)}
                        onClick={() => setIsPercentage(false)}
                    >
                        {t('Count')}
                    </Button>
                    <Button
                        variant="link"
                        className={cns(isPercentage && css.isActive)}
                        onClick={() => setIsPercentage(true)}
                    >
                        {t('Percentage')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
