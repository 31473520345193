import { Link as RouterLink } from '@reach/router';
import React from 'react';
import { cns } from '../../utils';

const isExternalLink = (url: string) => RegExp('^https?://').test(url);

export interface LinkProps {
    disabled?: boolean;
    to?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    children?: React.ReactNode;
    id?: string;
    className?: string;
    activeClassName?: string;
}

export const Link: React.FC<LinkProps> = (props: LinkProps) => {
    const { disabled, to, children, activeClassName, ...commonProps } = props;

    if (disabled || to === undefined) {
        return <span {...commonProps}>{children}</span>;
    }

    if (isExternalLink(to)) {
        return (
            <a href={to} {...commonProps}>
                {children}
            </a>
        );
    }

    return (
        <RouterLink
            to={to}
            {...commonProps}
            getProps={({ isCurrent }) => {
                return {
                    className: cns(commonProps.className, isCurrent && activeClassName),
                };
            }}
        >
            {children}
        </RouterLink>
    );
};
