import { Snackbar } from '@mui/material';
import React from 'react';
import css from './Toast.module.scss';
import Close from '../../assets/close.svg';
import { useActions, useOvermindState } from '../../state';
import { cns } from '../../utils';

export const Toast: React.FC = () => {
    const { toastMessages } = useOvermindState();
    const { dismissToastMessage } = useActions();

    return (
        <>
            {toastMessages.map((msg) => (
                <Snackbar
                    key={msg.id}
                    open={toastMessages.length > 0}
                    autoHideDuration={msg.timeout}
                    onClose={() => dismissToastMessage(msg.id)}
                >
                    <div className={cns('toast', css.toast)} key={msg.id}>
                        <p>{msg.message}</p>
                        <Close className="close-button" onClick={() => dismissToastMessage(msg.id)} />
                    </div>
                </Snackbar>
            ))}
        </>
    );
};
