import React from 'react';
import { render } from 'react-dom';
import App from './App';
import './common.module.scss';
import { REACT_ROOT } from './config';
import './i18n';

render(<App />, document.getElementById(REACT_ROOT));

// HMR interface
if (module.hot) {
    module.hot.accept('./App.tsx', async () => {
        const NextApp = (await import('./App')).default;
        render(<NextApp />, document.getElementById(REACT_ROOT));
    });
}
