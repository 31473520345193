import React from 'react';
import { cns } from '../../utils';
import { Button, ButtonProps } from '../Button/Button';
import { MainLoadingAnimation } from '../MainLoadingAnimation/MainLoadingAnimation';
import css from './StatusPage.module.scss';

interface StatusPageProps {
    buttonProps?: ButtonProps;
    isLoading: boolean;
    title: string;
}

export const StatusPage: React.FC<StatusPageProps> = ({ buttonProps, isLoading, title }: StatusPageProps) => {
    return (
        <div className={css.statusPageContainer}>
            {isLoading && <MainLoadingAnimation />}
            <h1 className={css.statusPageTitle}>{title}</h1>
            {!isLoading && buttonProps && (
                <Button {...buttonProps} className={cns(css.statusPageButton, buttonProps.className)} />
            )}
        </div>
    );
};
