import React from 'react';
import { cns } from '../../utils';
import { Spinner } from '../Spinner/Spinner';
import css from './Table.module.scss';

export type Accessor<T> = (row: T) => React.ReactNode;

export interface Column<T> {
    title: string;
    accessor: keyof T | Accessor<T>;
    className?: string;
}

export interface TableProps<T> {
    columns: Column<T>[];
    data: T[];
    className?: string;
    isLoading?: boolean;
    noRowsMessage?: string;
    onRowClick?: (row: T) => void;
    id?: string;
}

function isFunctionAccessor<T>(accessor: keyof T | Accessor<T>): accessor is Accessor<T> {
    return typeof accessor === 'function';
}

function access<T>(row: T, accessor: keyof T | Accessor<T>) {
    if (isFunctionAccessor(accessor)) {
        return accessor(row);
    }
    return row[accessor] as React.ReactNode;
}

export function Table<T>({
    columns,
    data,
    className,
    noRowsMessage,
    isLoading,
    onRowClick,
    id,
}: TableProps<T>): JSX.Element {
    const shouldShowRows = !isLoading && data.length > 0;
    const shouldShowNoRowsMessage = !isLoading && !shouldShowRows && noRowsMessage !== undefined;
    return (
        <table className={cns(css.table, className)} id={id}>
            <thead>
                <tr>
                    {columns.map(({ title, className }, colIndex) => (
                        <th key={colIndex} className={cns(className)}>
                            {title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {isLoading && (
                    <tr>
                        <td colSpan={columns.length} className={css.spinnerContainer}>
                            <Spinner className={css.spinner} size={5} />
                        </td>
                    </tr>
                )}
                {shouldShowRows &&
                    data.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex} onClick={() => onRowClick && onRowClick(row)}>
                                {columns.map(({ accessor, className }, colIndex) => (
                                    <td key={colIndex} className={className}>
                                        {access(row, accessor)}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                {shouldShowNoRowsMessage && (
                    <tr>
                        <td colSpan={columns.length} className={css.noRowsMessage}>
                            {noRowsMessage}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
