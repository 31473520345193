import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pie, PieChart } from 'recharts';
import {
    EquipmentAvailabilityKPI,
    PreventiveMaintenanceCompletionKPI,
    ResponseTimeKPI,
} from '../../../interfaces/customer-api';
import { LoadingState, TimeKeyFormat } from '../../../interfaces/internal';
import { KALMAR_RED, KALMAR_PRIMARY_GRAPE } from '../../colors';
import { useActions, useOvermindState } from '../../state';
import { cns, formatHoursAndMinutes, isFailed, isLoading } from '../../utils';
import { Button } from '../Button/Button';
import { Link } from '../Link/Link';
import { LoadingFailure } from '../LoadingFailure/LoadingFailure';
import { Spinner } from '../Spinner/Spinner';
import { TimeUnitSelector } from '../TimeUnitSelector/TimeUnitSelector';
import css from './MaintenanceReportingWidget.module.scss';

interface ChartDataObject {
    name: string;
    count: number;
    fill: string;
}
type ChartData = ChartDataObject[];

function renderPieChart(
    isDisabled: boolean,
    isLoading: boolean,
    loadingFailed: boolean,
    title: string,
    description: string,
    percentage: number | null,
    chartData: ChartData,
    linkTo: string,
    t: TFunction,
    id: string,
) {
    return (
        <div className={isDisabled ? cns(css.chartContainer, css.disabled) : css.chartContainer}>
            <h3>{title}</h3>
            {isLoading && (
                <div className={css.pieContainer}>
                    <Spinner size={3} />
                </div>
            )}
            {loadingFailed && <LoadingFailure />}
            {!isLoading && !loadingFailed && chartData && (
                <>
                    <Link id={`${id}-pie-chart`} className={css.pieContainer} to={linkTo} disabled={isDisabled}>
                        <PieChart width={120} height={120}>
                            <Pie
                                data={chartData}
                                dataKey="count"
                                cx="50%"
                                cy="50%"
                                innerRadius={50}
                                outerRadius={58}
                                startAngle={90}
                                endAngle={450}
                                blendStroke={true}
                                isAnimationActive={false}
                            />
                        </PieChart>
                        <div className={css.countContainer}>
                            <span>{percentage !== null ? `${(percentage * 100).toFixed(1)}%` : t('No data')}</span>
                        </div>
                    </Link>
                    <>
                        <p className={css.darkTextMediumEmphasis}>{description}</p>
                        <Button
                            isDisabled={isDisabled}
                            text={t('See details')}
                            variant="link"
                            linkTo={linkTo}
                            id={id}
                        />
                    </>
                </>
            )}
        </div>
    );
}

function renderEquipmentAvailabilityPieChart(loadingState: LoadingState, t: TFunction, KPI?: EquipmentAvailabilityKPI) {
    const chartData =
        KPI && KPI.average !== null
            ? [
                  {
                      name: 'available',
                      count: KPI.average * 100,
                      fill: KALMAR_PRIMARY_GRAPE,
                  },
                  {
                      name: 'downtime',
                      count: 100 - KPI.average * 100,
                      fill: KALMAR_RED,
                  },
              ]
            : [];

    return renderPieChart(
        KPI?.average === null,
        isLoading(loadingState),
        isFailed(loadingState),
        t('Availability'),
        t('Average percentage the equipment was available'),
        KPI ? KPI.average : null,
        chartData,
        '/maintenance-reporting/equipment-availability',
        t,
        'equipment-availability-details-button',
    );
}

function renderPMCompletionPieChart(
    loadingState: LoadingState,
    t: TFunction,
    KPI?: PreventiveMaintenanceCompletionKPI,
) {
    const chartData =
        KPI && KPI.all.planned !== 0
            ? [
                  {
                      name: 'completed',
                      count: KPI.all.completed,
                      fill: KALMAR_PRIMARY_GRAPE,
                  },
                  {
                      name: 'not-completed',
                      count: KPI.all.planned - KPI.all.completed,
                      fill: KALMAR_RED,
                  },
              ]
            : [];

    return renderPieChart(
        KPI?.all.planned === 0,
        isLoading(loadingState),
        isFailed(loadingState),
        t('Maintenance'),
        t('Planned maintenance operations that were completed on time'),
        KPI && KPI.all.planned !== 0 ? KPI.all.percentage : null,
        chartData,
        '/maintenance-reporting/preventive-maintenance',
        t,
        'see-maintenance-details-button',
    );
}

function renderResponseTime(loadingState: LoadingState, t: TFunction, KPI?: ResponseTimeKPI) {
    const averageMinutes = KPI?.average_minutes;
    const isDisabled = averageMinutes === null;
    const isLoadingKPI = isLoading(loadingState);
    const loadingFailed = isFailed(loadingState);
    return (
        <div className={isDisabled ? cns(css.chartContainer, css.disabled) : css.chartContainer}>
            <h3>{t('Response Time')}</h3>
            {isLoadingKPI && (
                <div className={css.pieContainer}>
                    <Spinner size={3} />
                </div>
            )}
            {loadingFailed && <LoadingFailure />}
            {!isLoadingKPI && !loadingFailed && (
                <>
                    <Link className={css.pieContainer} to="/maintenance-reporting/response-time" disabled={isDisabled}>
                        <div className={css.countContainer}>
                            <span>
                                {averageMinutes !== null && averageMinutes !== undefined
                                    ? `${formatHoursAndMinutes(averageMinutes)}`
                                    : t('No data')}
                            </span>
                        </div>
                    </Link>
                    <>
                        <p className={css.darkTextMediumEmphasis}>
                            {t('Average duration from breakdown to the first service operation start')}
                        </p>
                        <Button
                            isDisabled={isDisabled}
                            text={t('See details')}
                            variant="link"
                            linkTo={'/maintenance-reporting/response-time'}
                            id="see-reponse-time-details-button"
                        />
                    </>
                </>
            )}
        </div>
    );
}

export const MaintenanceReportingWidget: React.FC = () => {
    const {
        selectedEquipmentAvailabilityKPI,
        selectedPMCompletionKPI,
        selectedResponseTimeKPI,
        maintenanceReportingSelectedDate,
        loadingStates,
    } = useOvermindState();
    const { handleMaintenanceReportingDateChange, getMaintenanceReportingKPIs } = useActions();
    const { t } = useTranslation('MaintenanceReportingWidget');

    const selectedMonthKey = maintenanceReportingSelectedDate.toFormat(TimeKeyFormat.month);

    const loadingAnyKPIFailed = isFailed([
        loadingStates.PMCompletionKPIs[selectedMonthKey],
        loadingStates.equipmentAvailabilityKPIs[selectedMonthKey],
        loadingStates.responseTimeKPIs[selectedMonthKey],
    ]);

    return (
        <div className={css.maintenanceMetricsWidget}>
            <TimeUnitSelector
                handleChange={handleMaintenanceReportingDateChange}
                selectedDate={maintenanceReportingSelectedDate}
                timeUnit="month"
                className={css.monthSelector}
            />
            <div className={css.charts}>
                {renderPMCompletionPieChart(
                    loadingStates.PMCompletionKPIs[selectedMonthKey],
                    t,
                    selectedPMCompletionKPI,
                )}
                {renderEquipmentAvailabilityPieChart(
                    loadingStates.equipmentAvailabilityKPIs[selectedMonthKey],
                    t,
                    selectedEquipmentAvailabilityKPI,
                )}
                {renderResponseTime(loadingStates.responseTimeKPIs[selectedMonthKey], t, selectedResponseTimeKPI)}
            </div>
            {loadingAnyKPIFailed && (
                <LoadingFailure retry={getMaintenanceReportingKPIs} textClassName={css.loadingFailureHiddenText} />
            )}
        </div>
    );
};
