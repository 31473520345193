import { useMediaQuery } from '@mui/material';
import React from 'react';
import { BREAKPOINT_SMALL } from '../../config';
import { cns } from '../../utils';
import css from './SubPageContainer.module.scss';

interface SubPageContainerProps {
    children: React.ReactNode;
    idSlug: string;
    className?: string;
}

export const SubPageContainer: React.FC<SubPageContainerProps> = ({
    children,
    idSlug,
    className,
}: SubPageContainerProps) => {
    const isMobile = useMediaQuery(BREAKPOINT_SMALL);
    return (
        <div
            className={cns(isMobile ? css.subPageContainerMobile : css.subPageContainer, className)}
            id={`${idSlug}-page`}
        >
            {children}
        </div>
    );
};
