import React from 'react';
import { getIconForEquipmentType } from '../../equipment-icons';
import KalmarLogo from '../../assets/kalmar-logo-new-round.svg';

interface EquipmentIconProps {
    type: string | null;
}

export const EquipmentIcon: React.FC<EquipmentIconProps> = ({ type }: EquipmentIconProps) => {
    const iconComponent = getIconForEquipmentType(type);
    return iconComponent ? React.createElement(iconComponent) : <KalmarLogo />;
};
