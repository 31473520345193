import { ClickAwayListener, Modal, useMediaQuery } from '@mui/material';
import isFunction from 'lodash/isFunction';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dropdownIconUrl from '../../assets/master-header-icons/select-arrow-icon.url.svg';
import logoUrl from '../../assets/master-header-icons/kalmar-logo-new.url.svg';
import userIconUrl from '../../assets/master-header-icons/user-icon.url.svg';
import logOutIconUrl from '../../assets/master-header-icons/log-out.url.svg';
import { useActions, useOvermindState } from '../../state';
import { cns, getCaseManagementLink, getInsightLink, mkEnv, nonNullable } from '../../utils';
import { HelperPopup } from '../HelperPopup/HelperPopup';
import { LanguageSelectorModal } from '../LanguageSelectorModal/LanguageSelectorModal';
import { Link } from '../Link/Link';
import css from './MasterHeader.module.scss';

interface MasterHeaderDropDownOption {
    text: string;
    id?: string;
    href?: string;
    onClick?: () => void;
}

interface MasterHeaderDropDownProps {
    className?: string;
    id?: string;
    align?: 'left' | 'right';
    value: MasterHeaderDropDownOption | string;
    labelIconUrl?: string;
    options?: MasterHeaderDropDownOption[];
    onOpen?: () => void;
    tooltipText?: string;
}

const MasterHeaderDropDown: React.FunctionComponent<MasterHeaderDropDownProps> = ({
    value,
    options,
    className,
    id,
    labelIconUrl,
    align = 'left',
    onOpen,
    tooltipText,
}: MasterHeaderDropDownProps) => {
    const alignClassName = align === 'right' ? css.submenuAlignRight : css.submenuAlignLeft;
    const isStatic = !options || options.length < 1;
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () =>
        setIsOpen((isOpen) => {
            if (!isOpen && isFunction(onOpen)) {
                onOpen();
            }
            return !isOpen;
        });

    const close = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    const handleBlur: React.FocusEventHandler<HTMLUListElement> = (event) => {
        // Close when focus is lost
        if (!event.currentTarget.matches(':focus-within')) {
            close();
        }
    };

    return (
        <ClickAwayListener onClickAway={close}>
            <button
                className={cns(css.dropdown, isStatic && css.dropdownStatic, className)}
                onClick={toggle}
                id={id}
                tabIndex={isStatic ? -1 : undefined}
            >
                <div className={cns(css.dropdownLabel, !isStatic && css.ripple)}>
                    {labelIconUrl && <img src={labelIconUrl} className={css.dropdownLabelIcon} />}
                    <span className={css.dropdownLabelText} id={`${id}-value`}>
                        {typeof value === 'string' ? (
                            value
                        ) : (
                            <>
                                {/* Making MyKalmar text two different colours 
                                    The text should never be anything else than MyKalmar */}
                                <span className={css.redText}>{value.text.substring(0, 2)}</span>
                                <span className={css.whiteText}>{value.text.substring(2, value.text.length)}</span>
                            </>
                        )}
                    </span>
                    {!isStatic && <img src={dropdownIconUrl} className={css.dropdownLabelArrow} />}
                </div>
                {!isStatic && (
                    //  TODO: Fix this to use better dropdown menu. Check SiteSelector dropdown for reference
                    <ul className={cns(css.submenu, isOpen && css.submenuOpen, alignClassName)} onBlur={handleBlur}>
                        {(options ?? []).map(({ text, href, onClick, id }) => {
                            return (
                                <li key={text}>
                                    {href && (
                                        <a
                                            id={id}
                                            className={css.submenuLink}
                                            href={href}
                                            onClick={onClick}
                                            tabIndex={isOpen ? undefined : -1}
                                        >
                                            <span>{text}</span>
                                        </a>
                                    )}
                                    {!href && onClick && (
                                        <button
                                            id={id}
                                            className={css.submenuLink}
                                            onClick={onClick}
                                            tabIndex={isOpen ? undefined : -1}
                                        >
                                            <span>{text}</span>
                                        </button>
                                    )}
                                    {!href && !onClick && (
                                        <span id={id} className={css.submenuText}>
                                            {text}
                                        </span>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}
                {tooltipText && !isStatic && !isOpen && <div className={css.dropdownTooltip}>{tooltipText}</div>}
            </button>
        </ClickAwayListener>
    );
};

export const MasterHeader: React.FunctionComponent = () => {
    const { canNavigateToMyParts, isChineseCustomer, user, userAccess } = useOvermindState();
    const { handleLogout, closeSession, saveServiceMenuHelperShown } = useActions();
    const { t } = useTranslation('MasterHeader');
    const [isLanguageSelectionModalOpen, setIsLanguageSelectionModalOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 750px)'); // set to 750px in css

    const insightLoginPath = user?.isAdmin ? 'internal' : 'login/salesforce';
    const canNavigateToInsight = userAccess.kalmarInsight.read;

    const serviceOptions = [
        canNavigateToMyParts || canNavigateToInsight
            ? { id: 'header-to-my-kalmar-homepage', text: 'MyKalmar', href: '/' }
            : null,
        canNavigateToMyParts
            ? { id: 'header-to-my-kalmar-store-link', text: 'MyKalmar STORE', href: mkEnv.myPartsURL }
            : undefined,
        canNavigateToInsight
            ? {
                  id: 'header-to-insight-link',
                  text: 'MyKalmar Insight',
                  href: getInsightLink(isChineseCustomer, insightLoginPath),
              }
            : undefined,
    ].filter(nonNullable);

    const userMenuOptions = [
        isMobile // show user's full name in the dropdown on mobile
            ? {
                  text: user?.name ?? '',
                  id: 'userMenuFullName',
              }
            : null,
        user?.isAdmin
            ? {
                  text: t('Language'),
                  id: 'language-select-link',
                  onClick: () => setIsLanguageSelectionModalOpen(true),
              }
            : {
                  text: t('MyKalmar profile'),
                  id: 'mykalmar-profile-link',
                  href: getCaseManagementLink(`/s/profile/${user?.id}`),
                  onClick: closeSession,
              },
    ].filter(nonNullable);

    const handleServiceMenuOpen = () => {
        if (user && !user.flags.serviceMenuHelperShown) {
            saveServiceMenuHelperShown();
        }
    };

    const shouldShowServiceHelper = user && !user.flags.serviceMenuHelperShown && serviceOptions.length > 1;
    const shouldShowServiceTooltip = !shouldShowServiceHelper && serviceOptions.length > 1;

    return (
        <div className={css.kalmarMasterHeader}>
            <Link id="to-front-page-button" className={css.logo} to="/">
                <img src={logoUrl} />
            </Link>
            {shouldShowServiceHelper && (
                <HelperPopup
                    className={css.serviceDropdownHelperPopup}
                    text={t('You can go to MyKalmar STORE and MyKalmar Insight by clicking here')}
                    onClose={saveServiceMenuHelperShown}
                    id="service-menu-helper"
                />
            )}
            <MasterHeaderDropDown
                id="service-menu"
                options={serviceOptions}
                value={serviceOptions[0] ?? 'MyKalmar'}
                onOpen={handleServiceMenuOpen}
                tooltipText={shouldShowServiceTooltip ? t('Go to MyKalmar STORE or MyKalmar Insight') : undefined}
            />
            <MasterHeaderDropDown
                id="user-menu"
                className={cns(css.userDropdown, css.autoLeftMargin)}
                value={user?.name ?? ''}
                labelIconUrl={userIconUrl}
                align="right"
                options={userMenuOptions}
            />
            <button id="log-out-button" className={css.logOut} onClick={handleLogout}>
                {t('Log out')}
                <img src={logOutIconUrl} />
            </button>
            <Modal open={isLanguageSelectionModalOpen} onClose={() => setIsLanguageSelectionModalOpen(false)}>
                <LanguageSelectorModal onClose={() => setIsLanguageSelectionModalOpen(false)} />
            </Modal>
        </div>
    );
};
