export default {
    App: {
        'Contact Kalmar': 'Kontakta Kalmar',
        'Create new': 'Skapa nytt',
        'Dealer community': 'Dealer community',
        'Download failed': 'Hämtning misslyckades',
        'Failed to get service reports': 'Kunde inte hämta servicerapporter',
        'Loading MyKalmar': 'Läser in MyKalmar',
        'Login again': 'Logga in på nytt',
        'Maintenance reporting': 'Underhållsrapportering',
        'Maintenance schedule': 'Underhållsschema',
        'My Cases': 'Mina ärenden',
        'MyKalmar homepage': 'MyKalmar hemsida',
        'MyParts': 'MyParts',
        'Navigating to MyParts failed': 'Kunde inte navigera till MyParts',
        'No service reports found': 'Inga servicerapporter hittades',
        'None': 'Ingen',
        'Open Dealer community': 'Open Dealer community',
        'Open MyKalmar Insight': 'Öppna MyKalmar Insight',
        'Open MyKalmar STORE': 'Öppna MyKalmar STORE',
        'Open used equipment sales portal': 'Öppna portal för försäljning av begagnad utrustning',
        'Parts order history': 'Beställningshistorik',
        'Purchase used equipment': 'Köp begagnad utrustning',
        'Show all ({{ equipmentLength }}) equipment': 'Visa all ({{ equipmentLength }}) utrustning',
        'Show all Kalmar contacts': 'Visa alla kontaktpersoner för Kalmar',
        'Show cases': 'Visa ärenden',
        'Show on calendar': 'Visa på kalender',
        'Try again': 'Försök igen',
        'You are now logged out from MyKalmar': 'Du är nu utloggad från MyKalmar',
    },
    Common: {
        'Availability': 'Tillgänglighet',
        'Call the number': 'Ring nummer',
        'called': 'Anropat',
        'completed': 'Slutfört',
        'Contracts': 'Kontrakt',
        'declined': 'Nekat',
        'Maintenance': 'Underhåll',
        'Monthly': 'Månatligen',
        'Monthly report': 'Månadsrapport',
        'My Equipment': 'Min utrustning',
        'N/A': 'N/A',
        'Name': 'Namn',
        'No data': 'Inga data',
        'No data for this time period': 'Inga data för den här tidsperioden',
        'OK': 'OK',
        'ordered': 'Beställd',
        'Other': 'Övrigt',
        'Other Equipment': 'Övrig utrustning',
        'Response Time': 'Svarstid',
        'scheduled': 'Schemalagd',
        'See details': 'Se detaljer',
        'Serial number': 'Serienummer',
        'Site': 'Anläggning',
        'Something went wrong': 'Något gick fel',
        'Status': 'Status',
        'today': 'idag',
        'Type': 'Typ',
        'Unknown': 'Okänt',
        'Yearly': 'Årsvis',
        'Yearly trend': 'Årlig trend',
    },
    ContactWidget: {
        'Contact us!': 'Kontakta oss!',
        'Email': 'E-post',
        'Need help?  Or need to know more about our services?': 'Behöver du hjälp? Eller vill du veta mer om vårt erbjudande?',
        'Phone': 'Telefon',
        'Send email': 'Skicka email',
    },
    CookieConsentBanner: {
        'Learn more': 'Lär dig mer',
        'This website makes use of cookies to enhance browsing experience and provide additional functionality.': 'Denna webbplats använder cookies för att förbättra surfupplevelsen och möjliggör utökade funktioner.',
    },
    CustomerSelector: {
        'No results found with': 'Inga resultat hittades med',
        'Search with name, city, street address or sold-to number': 'Search with name, city, street address or sold-to number',
        'Select customer': 'Välj kund',
    },
    DealerCommunity: {
        'Sales & general information': 'Försäljning & allmän information',
        'Marketing & products': 'Marknadsföring & produkter',
        'Technical library': 'Tekhnsk bibiliotek',
        'News': 'Nyheter',
        'Kalmar contacts': 'Kalmar kontakter',
    },
    EquipmentAvailability: {
        'Equipment Type': 'Utrustningstyp',
        'Month': 'Månad',
        'Quarter': 'Kvartal',
        'Year': 'År',
    },
    EquipmentAvailabilityByType: {
        'Time spent': 'Spenderad tid',
    },
    Footer: {
        'General conditions': 'allmänna villkor',
        'Kalmar in Social Media': 'Kalmar i sociala medier',
        'Kalmar Digital Service Terms & Conditions': 'Regler och villkor för Kalmar Digital Service',
        'Privacy policy': 'Integritetspolicy',
    },
    KalmarInsightWidget: {
        'equipment': 'utrustning',
        'Expired license': 'Avslutad licens',
        'License expiring soon': 'Licens upphör snart',
        'Manage users': 'Hantera användare',
        'Number of MyKalmar Insight users': 'Antal användare av MyKalmar Insight',
        'Valid license': 'Giltig licens',
    },
    LanguageSelector: {
        'Cancel': 'Avbryt',
        'Select language': 'Välj språk',
    },
    LoadingFailure: {
        'Loading cases failed': 'Inläsning av ärenden misslyckades',
        'Loading equipment availability failed': 'Inläsning av utrustningstillgänglighet misslyckades',
        'Loading equipment failed': 'Inläsning av utrustning misslyckades',
        'Loading failed': 'Inläsning misslyckades',
        'Loading maintenance schedule failed': 'Inläsning av underhållsschema misslyckades',
        'Loading preventive maintenance data failed': 'Inläsning av data för förebyggande underhåll misslyckades',
        'Loading response time failed': 'Inläsning av svarstid misslyckades',
        'Loading sales orders failed': 'Inläsning av försäljningsorder misslyckades',
        'Retry': 'Försök igen',
    },
    MaintenanceCalendar: {
        'Equipment name': 'Utrustningsnamn',
        'Maintenance description': 'Underhållsbeskrivning',
        'No maintenance scheduled for the selected day.': 'Inget underhåll planerat för vald dag.',
    },
    MaintenanceReporting: {
        'Equipment Availability': 'Utrustningstillgänglighet',
        'Preventive Maintenance': 'Förebyggande underhåll',
    },
    MaintenanceReportingWidget: {
        'Average duration from breakdown to the first service operation start': 'Average duration from breakdown to the first service operation start',
        'Average percentage the equipment was available': 'Genomsnittlig procentandel då utrustningen var tillgänglig',
        'Planned maintenance operations that were completed on time': 'Planned maintenance operations that were completed on time',
    },
    MaintenanceScheduleWidget: {
        'No upcoming maintenances': 'Inget kommande underhåll',
        'Upcoming maintenances': 'Kommande underhåll',
    },
    MasterHeader: {
        'Go to MyKalmar STORE or MYKalmar Insight': 'Gå till MyKalmar STORE eller MyKalmar Insight',
        'Language': 'Language',
        'Log out': 'Logga ut',
        'MyKalmar profile': 'MyKalmar profile',
        'You can go to MyKalmar STORE and MyKalmar Insight by clicking here': 'Klicka här för att ta dig till MyKalmar STORE och MyKalmar Insight',
    },
    MyCasesWidget: {
        'An unknown value': 'Ett okänt värde',
        'caseUpdateFieldChange': '{{fieldName}} ändrat från <2>{{oldValue}}</2> till <4>{{newValue}}</4>',
        'Comment': 'Kommentar',
        'n/a': 'ej tillämpligt',
        'New comment': 'Ny kommentar',
        'No recent updates to cases': 'Inga nyliga uppdateringar av ärenden',
        'Priority': 'Prioritet',
        'Recent updates': 'Nyliga uppdateringar',
        'Resolution_Date__c': 'Resolution date',
    },
    MyEquipment: {
        'Contracts': 'Kontrakt',
        'Description': 'Beskrivning',
        'Digital subscriptions': 'Digitala prenumerationer',
        'Equipment name': 'Utrustningsnamn',
        'MyKalmar Insight': 'MyKalmar Insight',
        'Leasing': 'Leasing',
        'Model': 'Modell',
        'Search equipment with name, site, serial number or type': 'Sök efter utrustning med hjälp av namn, anläggning serienummer eller typ',
        'Search with name, serial number or type': 'Sök med hjälp av namn serienummer eller typ',
        'Serial number': 'Serienummer',
        'Short-term rental': 'Korttidshyra',
    },
    MyEquipmentItem: {
        'Contract ends': 'Kontrakt upphör',
        'Contract started': 'Kontrakt började gälla',
        'Description': 'Beskrivning',
        'Equipment details': 'Utrustningsdetaljer',
        'insight': 'MyKalmar Insight',
        'leasing': 'Leasing',
        'Machine card': 'Maskinkort',
        'maintenance': 'Underhåll',
        'Maintenance schedule': 'Underhållsschema',
        'Manufacturing year': 'Tillverkningsår',
        'Model': 'Modell',
        'No data': 'Inga data',
        'No equipment found': 'Ingen utrustning hittades',
        'Open': 'Öppna',
        'Order parts': 'Beställ delar',
        'Owner': 'Ägare',
        'rental-short-term': 'Korttidshyra',
        'See Insights': 'Se insikter',
        'Serial number': 'Serienummer',
        'Today': 'Idag',
    },
    MyEquipmentWidget: {},
    MyPartsWidget: {
        'At warehouse': 'På lagret',
        'Customer PO': 'Kunds inköpsordernummer',
        'Delivered': 'Levererad',
        'My last parts orders': 'Mina senaste reservdelsorder',
        'No orders': 'Inga order',
        'Note: this order will be delivered in multiple shipments.': 'Observera: Denna beställning kommer att levereras i flera sändningar.',
        'Order number': 'Ordernummer',
        'Order received': 'Beställning mottagen',
        'Processing': 'Bearbetning',
        'Shipment': 'Leverans',
        'Shipped': 'Skickad',
        'Tracking': 'Spårning',
    },
    NotFound: {
        'Go to home page': 'Gå till startsida',
        'Page not found': 'Sida hittades inte',
        'The page may have been moved or you may not have the necessary rights to access this page': 'Den här sidan kanske redan har flyttats eller så har du inte behörighet att öppna sidan',
    },
    PreventiveMaintenanceCompletion: {
        'Completed': 'Slutfört',
        'completed in': 'slutfört inom',
        'Count': 'Antal',
        'No data for': 'Inga data för',
        'Not completed': 'Ej slutfört',
        'Percentage': 'Procentandel',
        'Planned': 'Planerat',
        'tasks': 'uppgifter',
    },
    ResponseTime: {
        'Action started': 'Åtgärd påbörjad',
        'avg. response time': 'gen. svarstid',
        'Call started': 'Anrop startat',
        'in': 'tum',
        'Operation': 'Drift',
        'Response time': 'Svarstid',
    },
    SessionExpirationModal: {
        'Log in again': 'Logga in på nytt',
        'You have been logged out due to inactivity': 'Du har loggats ut på grund av inaktivitet',
        'Your session has expired': 'Din session har löpt ut',
    },
    SiteSelector: {
        'Customer': 'Kund',
        'No sites with equipment': 'Inga anläggningar med utrustning',
        'Select a customer': 'Välj en kund',
        'Showing all sites': 'Visar alla anläggningar',
        'Selected': 'Valda',
        'Show all sites': 'Visa alla anläggnignar',
    },
    TimeUnitLinks: {
        'Show': 'Visa',
    },
    Tooltips: {
        'Content': 'Content',
        'Recent reports': 'Recent reports',
    },
};
