import React from 'react';
import { useTranslation } from 'react-i18next';
import { cns } from '../../utils';
import { Button } from '../Button/Button';
import css from './LoadingFailure.module.scss';

export interface LoadingFailureProps {
    translationKey?: string;
    retry?: () => Promise<void>;
    textClassName?: string;
}

export const LoadingFailure: React.FC<LoadingFailureProps> = ({
    translationKey = 'Loading failed',
    retry,
    textClassName,
}: LoadingFailureProps) => {
    const { t } = useTranslation('LoadingFailure');
    return (
        <div className={css.loadingFailure}>
            <div className={cns(css.text, textClassName)}>{t(translationKey)}</div>
            {retry && (
                <Button id={`${translationKey}-retry-button`} variant="secondary" onClick={retry}>
                    {t('Retry')}
                </Button>
            )}
        </div>
    );
};
