import React from 'react';
import { cns } from '../../utils';
import { Link } from '../Link/Link';
import css from './Button.module.scss';

type ButtonVariant = 'primary' | 'secondary' | 'text' | 'link' | 'unstyled';

export interface ButtonProps {
    isDisabled?: boolean;
    text?: string;
    variant: ButtonVariant;
    linkTo?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    children?: React.ReactNode;
    id?: string;
    className?: string;
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { children, isDisabled, linkTo, variant, text, ...restProps } = props;

    const commonProps = {
        ...restProps,
        className: cns('button', props.className, css.button, css[variant], isDisabled && css.disabled),
        disabled: isDisabled,
    };

    if (linkTo) {
        return (
            <Link to={linkTo} {...commonProps}>
                {text ? text : children}
            </Link>
        );
    }

    return <button {...commonProps}>{text ? text : children}</button>;
};
