import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '../Link/Link';
import css from './NotFound.module.scss';

type NotFoundProps = RouteComponentProps;

export const NotFound: React.FC<NotFoundProps> = () => {
    const { t } = useTranslation('NotFound');

    return (
        <div className={css.notFound}>
            <h1>{t('Page not found')}</h1>
            <p>{t('The page may have been moved or you may not have the necessary rights to access this page')}</p>
            <Link to="/">{t('Go to home page')}</Link>
        </div>
    );
};
