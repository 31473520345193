import { useLocation, useNavigate } from '@reach/router';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Breadcrumb } from '../interfaces/internal';
import { useActions } from './state';

// Custom hook to wait async functions. Useful for making API calls in components
export const useAsyncData = <T>(
    asyncFunction: () => Promise<T>,
    initialState: T,
    deps: React.DependencyList | undefined = [],
): [T, boolean] => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(initialState);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const response = await asyncFunction();
            setData(response);
            setIsLoading(false);
        })();
    }, deps);

    return [data, isLoading];
};

// Custom hook for updating breadcrumbs
export const useBreadcrumb = (partialCrumb: Pick<Breadcrumb, 'key' | 'title'>): void => {
    const actions = useActions();
    const { pathname, search } = useLocation();

    useEffect(() => {
        // exclude next
        const excludeNext = queryString.exclude(search, ['next']);
        const parseExcludeNext = queryString.parse(excludeNext);
        const searchWithoutNext = queryString.stringify(parseExcludeNext);

        actions.addBreadCrumb({ ...partialCrumb, pathname: `${pathname}${searchWithoutNext}` });
    }, [pathname, search]);
};

// Custom hook for keeping state in URL search/query params
export const useURLSearchParam = (key: string): [string, (nextVal: string) => void] => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const currentParams = new URLSearchParams(search);
    const value = currentParams.get(key) ?? '';

    const setValue = (nextVal: string) => {
        const { pathname, search } = window.location;
        const nextParams = new URLSearchParams(search);
        if (nextVal.length > 0) {
            nextParams.set(key, nextVal);
        } else {
            nextParams.delete(key);
        }
        navigate(`${pathname}?${nextParams.toString()}`, { replace: true });
    };

    return [value, setValue];
};

export type SortOption<T> = { key: keyof T; direction: 'asc' | 'desc'; trKey: string };
export function useSortOptions<T>(sortOptions: SortOption<T>[]): [SortOption<T>, (nextOption: SortOption<T>) => void] {
    const [sortKey, setSortKey] = useURLSearchParam('sort_by');
    const [sortDirection, setSortDirection] = useURLSearchParam('sort_direction');

    const defaultSortOption = sortOptions[0];
    const selectedSortOption =
        sortOptions.find(({ key, direction }) => key === sortKey && direction === sortDirection) ?? defaultSortOption;

    const setSelectedSortOption = (sortOption: SortOption<T>) => {
        setSortKey(String(sortOption.key));
        setSortDirection(sortOption.direction);
    };

    return [selectedSortOption, setSelectedSortOption];
}
