import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { API_DATE_FORMAT, MaintenancePlan, MaintenanceStatus } from '../../../interfaces/customer-api';
import { useOvermindState } from '../../state';
import { formatDate } from '../../utils';
import { Link } from '../Link/Link';
import { WidgetSubHeading } from '../WidgetSubHeading/WidgetSubHeading';
import css from './MaintenanceScheduleWidget.module.scss';

function getRelativeDate(
    date: string | null,
    translationResponse: UseTranslationResponse<'MaintenanceScheduleWidget'>,
) {
    if (date) {
        const { t, i18n } = translationResponse;
        const plannedDate = DateTime.fromISO(date).setLocale(i18n.language).toLocal();
        const now = DateTime.local();
        const isToday = plannedDate.toFormat(API_DATE_FORMAT) === now.toFormat(API_DATE_FORMAT);
        if (isToday) {
            return <strong>{t('today')}</strong>;
        }
        return <span>{plannedDate.toRelativeCalendar()}</span>;
    }
    return null;
}

function renderMaintenancePlan(
    maintenancePlan: MaintenancePlan,
    index: number,
    translationResponse: UseTranslationResponse<'MaintenanceScheduleWidget'>,
) {
    const { description } = maintenancePlan;
    const { equipmentNumber, serialNumber, technicalIdentificationNumber } = maintenancePlan.equipment;
    const machineId = technicalIdentificationNumber || serialNumber;
    const relativeDate = getRelativeDate(maintenancePlan.date, translationResponse);
    const date = maintenancePlan.date ? formatDate(DateTime.fromISO(maintenancePlan.date)) : null;
    return (
        <li className={css.maintenancePlan} key={`${equipmentNumber}-${index}`}>
            <div>
                <div>
                    <Link to={`/my-equipment/${equipmentNumber}`} className={css.machineId}>
                        {machineId}
                    </Link>
                    <span className={css.divider}>-</span>
                    <>
                        {relativeDate && relativeDate}, <span>{date}</span>
                    </>
                </div>
                <span className={css.description}>{description}</span>
            </div>
        </li>
    );
}

export const MaintenanceScheduleWidget: React.FC = () => {
    const { nonDeclinedSiteMaintenancePlans } = useOvermindState();
    const translationResponse = useTranslation('MaintenanceScheduleWidget');
    const { t } = translationResponse;

    const today = DateTime.local().startOf('day');
    const upcoming = nonDeclinedSiteMaintenancePlans
        .filter((plan) => plan.date && DateTime.fromISO(plan.date) >= today)
        .filter((plan) => plan.status !== MaintenanceStatus.COMPLETED)
        .slice(0, 3);
    return (
        <div>
            <WidgetSubHeading title={`${t('Upcoming maintenances')}:`} />
            {!!upcoming.length && (
                <ul>{upcoming.map((plan, index) => renderMaintenancePlan(plan, index, translationResponse))}</ul>
            )}
            {!upcoming.length && <p>{t('No upcoming maintenances')}</p>}
        </div>
    );
};
