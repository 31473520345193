import React from 'react';
import { formatHoursAndMinutes } from '../../utils';
import css from './ChartHelpers.module.scss';

interface TickProps {
    x: number;
    y: number;
    payload: {
        value: string;
    };
}

export const MonthAxisTick: React.FC = (props: unknown) => {
    // Need to do prop typings like this because of how recharts typings work:
    const { x, y, payload } = props as TickProps;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={4} textAnchor="end" transform="rotate(-45)" className={css.tick}>
                {payload.value}
            </text>
        </g>
    );
};

export const DurationTick: React.FC = (props: unknown) => {
    const { x, y, payload } = props as TickProps;
    return (
        <text x={x} y={y} dy={4} textAnchor="end" className={css.tick}>
            {formatHoursAndMinutes(parseInt(payload.value, 10))}
        </text>
    );
};

export const BasicYTick: React.FC = (props: unknown) => {
    const { x, y, payload } = props as TickProps;
    return (
        <text x={x} y={y} dy={4} textAnchor="end" className={css.tick}>
            {payload.value}
        </text>
    );
};

export const PercentageTick: React.FC = (props: unknown) => {
    const { x, y, payload } = props as TickProps;
    return (
        <text x={x} y={y} dy={4} textAnchor="end" className={css.tick}>
            {parseInt(payload.value, 10)} %
        </text>
    );
};
