import groupBy from 'lodash/groupBy';
import memoize from 'lodash/memoize';
import range from 'lodash/range';
import { DateTime, Info } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API_DATE_FORMAT, MaintenancePlan, MaintenanceStatus } from '../../../interfaces/customer-api';
import { DateRange } from '../../../interfaces/internal';
import ChevronLeft from '../../assets/chevron-left.svg';
import ChevronRight from '../../assets/chevron-right.svg';
import { useActions, useOvermindState } from '../../state';
import { cns, formatDate, getMaintenancePlansForDateRange, isFailed, isLoading } from '../../utils';
import { Link } from '../Link/Link';
import { LoadingFailure } from '../LoadingFailure/LoadingFailure';
import { Spinner } from '../Spinner/Spinner';
import { Tooltip } from '../Tooltip/Tooltip';
import css from './MaintenanceCalendar.module.scss';

type NonDeclinedMaintenanceStatus = Exclude<MaintenanceStatus, 'declined'>;
type StatusOverview = Record<NonDeclinedMaintenanceStatus, number>;

// Calculating calendar days is relatively expensive and can be memoized:
const getCalendarDays = memoize((calendarDate: DateTime): PartialDayProps[] => {
    const { month, year } = calendarDate;

    const startOfSelectedMonth = DateTime.fromObject({ year: year, month: month }).startOf('month');
    const firstDate = startOfSelectedMonth.minus({ days: startOfSelectedMonth.weekday - 1 });

    const monthDays = range(0, 42).map((index) => {
        const date = firstDate.plus({ days: index });
        const isPadded = date.year !== calendarDate.year || date.month !== calendarDate.month;
        const dateStr = date.toFormat(API_DATE_FORMAT);

        const dateObject: PartialDayProps = {
            date,
            isPadded,
            dateStr,
        };
        return dateObject;
    });

    return monthDays;
});

const getStatusOverview = (maintenancePlans: MaintenancePlan[]): StatusOverview => {
    const statusGroups = groupBy(maintenancePlans, (plan) => plan.status);
    const statusOverview: StatusOverview = {
        called: 0,
        completed: 0,
        ordered: 0,
        scheduled: 0,
    };
    Object.keys(statusGroups).forEach((key) => {
        const statusKey = key as NonDeclinedMaintenanceStatus;
        const statusGroup = statusGroups[statusKey];
        if (statusGroup) {
            statusOverview[statusKey] = statusGroup.length;
        }
    });

    return statusOverview;
};

const getDotClassName = (plan: MaintenancePlan, index: number) => {
    let position;
    switch (index) {
        case 0:
            position = css.dot0;
            break;
        case 1:
            position = css.dot1;
            break;
        case 2:
            position = css.dot2;
            break;
        case 3:
            position = css.dot3;
            break;
        case 4:
            position = css.dot4;
            break;
        default:
            break;
    }
    return cns(css.dot, css[plan.status], position);
};

interface DayProps {
    /** DateTime object */
    date: DateTime;
    /** Is this date outside of currently selected year/month combination */
    isPadded: boolean;
    /** String representation in API date format (yyyyMMdd) */
    dateStr: string;
    isSelected: boolean;
}

type PartialDayProps = Omit<DayProps, 'isSelected'>;

const Day: React.FC<DayProps> = React.memo((props: DayProps) => {
    const { date, dateStr, isPadded, isSelected } = props;
    const { maintenancePlansForSelectedMonth } = useOvermindState().maintenanceCalendarState;
    const { setMaintenanceCalendarState } = useActions();
    const { t } = useTranslation('MaintenanceCalendar');
    const plansForDay = getMaintenancePlansForDateRange(maintenancePlansForSelectedMonth, {
        startDate: dateStr,
        endDate: dateStr,
    });
    const daysFromNow = date.endOf('day').diffNow('days').days;
    const isToday = daysFromNow < 1 && daysFromNow > 0;

    return (
        <span
            className={cns(css.day, isPadded && css.padded, isSelected && css.selected)}
            key={dateStr}
            onClick={() => {
                if (isPadded) return;
                setMaintenanceCalendarState({ selectedDay: date });
            }}
        >
            <span>
                {date.day}
                {isToday && <span className={css.today}>{t('today')}</span>}
            </span>
            {!isPadded &&
                plansForDay.map((plan, planIndex) => {
                    const { description } = plan;
                    const { equipmentNumber, technicalIdentificationNumber, serialNumber } = plan.equipment;
                    const tooltipTitle = (
                        <dl className={css.calendarTooltip}>
                            <dt>{t('Equipment name')}</dt>
                            <dd>{technicalIdentificationNumber || serialNumber}</dd>
                            <dt>{t('Maintenance description')}</dt>
                            <dd>{description}</dd>
                        </dl>
                    );
                    const dotKey = `${dateStr}-${equipmentNumber}-${status}-${description}-${planIndex}`;
                    return (
                        <Tooltip
                            key={dotKey}
                            placement="top"
                            title={tooltipTitle}
                            componentsProps={{ tooltip: { className: css.tooltip } }}
                        >
                            <span className={getDotClassName(plan, planIndex)} />
                        </Tooltip>
                    );
                })}
        </span>
    );
});

interface StatusOverviewProps {
    maintenancePlans: MaintenancePlan[];
}

const StatusOverview: React.FC<StatusOverviewProps> = React.memo(({ maintenancePlans }: StatusOverviewProps) => {
    const statusOverview = getStatusOverview(maintenancePlans);
    const { t } = useTranslation('MaintenanceCalendar');

    const statuses = Object.keys(statusOverview)
        .filter((key) => {
            const status = key as NonDeclinedMaintenanceStatus;
            return statusOverview[status] !== 0;
        })
        .map((key) => {
            const status = key as NonDeclinedMaintenanceStatus;
            return (
                <div className={css.item} key={`status-overview-${status}`}>
                    <span className={cns(css.dot, css[status])} />
                    <span>
                        {statusOverview[status]} {t(status)}
                    </span>
                </div>
            );
        });
    return statuses.length ? <div className={css.statusOverview}>{statuses}</div> : null;
});

const SelectedDayPlans: React.FC = React.memo(() => {
    const { selectedDay, maintenancePlansForSelectedDay } = useOvermindState().maintenanceCalendarState;
    const { i18n, t } = useTranslation('MaintenanceCalendar');
    const dateStr = selectedDay.toFormat(API_DATE_FORMAT);
    const plans = getMaintenancePlansForDateRange(maintenancePlansForSelectedDay, {
        startDate: dateStr,
        endDate: dateStr,
    });
    const selectedDayWithLocale = selectedDay.setLocale(i18n.language);

    const planElements = plans.map((plan, index) => {
        const { description, status } = plan;
        const { equipmentNumber, serialNumber, technicalIdentificationNumber } = plan.equipment;
        const equipmentTitle = technicalIdentificationNumber || serialNumber;

        const planKey = `plan-${equipmentNumber}-${status}-${description}-${index}`;
        return (
            <div className={css.selectedDayItem} key={planKey} id="maintenance-calendar-selected-day-items">
                <h3>
                    <Link to={`/my-equipment/${equipmentNumber}`}>{equipmentTitle}</Link>
                </h3>
                <dl>
                    <dt>{t('Status')}</dt>
                    <dd className={css.status}>
                        <span className={cns(css.dot, css[status])} />
                        <span className={css.statusText}>{status}</span>
                    </dd>
                    {technicalIdentificationNumber && (
                        <>
                            <dt>{t('Equipment name')}</dt>
                            <dd>{technicalIdentificationNumber}</dd>
                        </>
                    )}

                    <dt>{t('Serial number')}</dt>
                    <dd>{serialNumber}</dd>

                    <dt>{t('Maintenance description')}</dt>
                    <dd>{description}</dd>
                </dl>
            </div>
        );
    });

    return (
        <div className={css.selectedDayContainer}>
            <header>
                <h3>{`${selectedDayWithLocale.toFormat('EEEE')} ${formatDate(selectedDayWithLocale)}`}</h3>
            </header>
            <div className={css.selectedDay}>
                {planElements.length > 0 ? planElements : <p>{t('No maintenance scheduled for the selected day.')}</p>}
            </div>
        </div>
    );
});

export const MaintenanceCalendar: React.FC = () => {
    const { maintenanceCalendarState, loadingStates } = useOvermindState();
    const { calendarDate, selectedDay, maintenancePlansForSelectedMonth } = maintenanceCalendarState;
    const { i18n } = useTranslation('MaintenanceCalendar');
    const actions = useActions();

    const calendarDateWithLocale = calendarDate.setLocale(i18n.language);
    const isLoadingPlans = isLoading(loadingStates.maintenancePlans);
    const weekDayStrings = Info.weekdays('short', { locale: i18n.language });
    const selectedDayString = selectedDay.toFormat(API_DATE_FORMAT);
    const calendarDays = getCalendarDays(calendarDate);

    const goToPreviousMonth = async () => {
        const previousMonth = calendarDate.minus({ months: 1 }).startOf('month');
        actions.setMaintenanceCalendarState({
            calendarDate: previousMonth,
        });

        const dateRange: DateRange = {
            startDate: previousMonth.startOf('month').toFormat(API_DATE_FORMAT),
            endDate: previousMonth.endOf('month').toFormat(API_DATE_FORMAT),
        };

        await actions.getMoreMaintenancePlans(dateRange);
    };

    const goToNextMonth = () => {
        const nextMonth = calendarDate.plus({ months: 1 }).startOf('month');
        actions.setMaintenanceCalendarState({
            calendarDate: nextMonth,
        });
    };

    if (isFailed(loadingStates.maintenancePlans)) {
        return (
            <LoadingFailure
                translationKey="Loading maintenance schedule failed"
                retry={actions.getInitialMaintenancePlans}
            />
        );
    }

    return (
        <div className={css.mainContainer} id="maintenance-calendar">
            <div className={css.maintenanceCalendar}>
                <div className={css.calendarContainer}>
                    <header className={css.calendarHeader}>
                        <button disabled={isLoadingPlans} onClick={goToPreviousMonth}>
                            <ChevronLeft className={css.chevronLeft} />
                            {calendarDateWithLocale.minus({ months: 1 }).toFormat('MMMM')}
                        </button>
                        <h3>{calendarDateWithLocale.toFormat('MMMM yyyy')}</h3>
                        <button disabled={isLoadingPlans} onClick={goToNextMonth}>
                            {calendarDateWithLocale.plus({ months: 1 }).toFormat('MMMM')}
                            <ChevronRight className={css.chevronRight} />
                        </button>
                    </header>
                    <div className={css.calendar}>
                        <div className={css.weekdays}>
                            {weekDayStrings.map((weekday) => (
                                <span className={css.weekday} key={weekday}>
                                    {weekday}
                                </span>
                            ))}
                        </div>
                        <div className={css.days}>
                            {isLoadingPlans ? (
                                <Spinner size={5} className={css.spinner} />
                            ) : (
                                calendarDays.map((partialDayProps) => (
                                    <Day
                                        {...partialDayProps}
                                        isSelected={partialDayProps.dateStr === selectedDayString}
                                        key={partialDayProps.dateStr}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                    {!isLoadingPlans && <StatusOverview maintenancePlans={maintenancePlansForSelectedMonth} />}
                </div>
                <SelectedDayPlans />
            </div>
        </div>
    );
};
