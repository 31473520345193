import React from 'react';
import css from './MascusWidget.module.scss';
import MascusIcon from '../../assets/mascus-icon.svg';
import KalmarIcon from '../../assets/kalmar-logo-new-round.svg';

export const MascusWidget: React.FC = () => {
    return (
        <div>
            <div className={css.mascusIcon}>
                <MascusIcon />
            </div>
            <div className={css.middleContainer}>
                <div className={css.kalmarIcon}>
                    <KalmarIcon />
                </div>
                <div>
                    {/* We are not translating these texts */}
                    <p className={css.kalmarText}>Kalmar</p>
                    <p className={css.usedEquipmentText}>Used Equipment</p>
                    <p className={css.legendaryText}>Legendary reliability</p>
                </div>
            </div>
        </div>
    );
};
