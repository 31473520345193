import { navigate, RouteComponentProps } from '@reach/router';
import React from 'react';
import { WidgetBreadcrumb } from '../../../interfaces/internal';
import { getIconForEquipmentType } from '../../equipment-icons';
import { useOvermindState } from '../../state';
import { SubPage } from '../SubPage/SubPage';
import { WidgetProps } from '../Widget/Widget';

interface MyEquipmentOwnProps extends RouteComponentProps {
    widget: WidgetProps;
    children: React.ReactNode;
}

type MyEquipmentProps = MyEquipmentOwnProps;

const handleCloseClick = async () => {
    const splittedPath = window.location.pathname.split('/');
    if (splittedPath.length === 3) {
        await navigate(`/${splittedPath[1]}`);
    } else {
        await navigate('/');
    }
};

export const MyEquipment: React.FC<MyEquipmentProps> = ({ children, widget }: MyEquipmentProps) => {
    const { selectedEquipment } = useOvermindState();
    const breadcrumb: WidgetBreadcrumb = {
        id: widget.id,
        title: widget.title,
        subPage:
            selectedEquipment !== null
                ? {
                      icon: getIconForEquipmentType(selectedEquipment.type),
                      title: selectedEquipment.technical_identificationnumber || selectedEquipment.serial_number,
                  }
                : undefined,
    };

    return (
        <SubPage breadcrumb={breadcrumb} onCloseClick={handleCloseClick} idSlug={widget.id}>
            {children}
        </SubPage>
    );
};
