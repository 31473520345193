import React from 'react';
import { cns } from '../../utils';
import css from './ButtonGroup.module.scss';

type ButtonGroupProps = {
    children: React.ReactNode;
    className?: string;
};

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, className }: ButtonGroupProps) => {
    return <div className={cns(className, css.buttonGroup)}>{children}</div>;
};
