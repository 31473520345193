import React from 'react';
import { useTranslation } from 'react-i18next';
import { useActions, useOvermindState } from '../../state';
import { Button } from '../Button/Button';
import css from './CookieConsentBanner.module.scss';

/* TODO: Check with legal that this complies with GDPR/CCPA/etc */

export const CookieConsentBanner: React.FC = () => {
    const { t } = useTranslation('CookieConsentBanner');

    const { user } = useOvermindState();
    const { saveCookieBannerShown } = useActions();

    if (!user || user.flags.cookieBannerShown) return null;

    return (
        <div className={css.cookieConsentBanner} id="cookie-banner">
            <div className={css.bannerContent}>
                <div>
                    {t(
                        'This website makes use of cookies to enhance browsing experience and provide additional functionality.',
                    )}
                </div>
                <div className={css.buttons}>
                    <Button
                        variant="unstyled"
                        className={css.learnMoreButton}
                        linkTo="https://www.kalmarglobal.com/legal-notice/privacy-policy/"
                    >
                        {t('Learn more')}
                    </Button>
                    <Button variant="primary" className={css.okButton} onClick={saveCookieBannerShown}>
                        {t('OK')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
