import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';

import css from './SessionExpiredModal.module.scss';

export const SessionExpiredModal = React.forwardRef<HTMLDivElement>((props, ref) => {
    const { t } = useTranslation('SessionExpiredModal');

    return (
        <div id="session-expired-modal" className={css.sessionExpiredModal} ref={ref} tabIndex={-1}>
            <header>
                <h1>{t('Your session has expired')}</h1>
            </header>
            <section>
                <p>{t('You have been logged out due to inactivity')}</p>
                <Button
                    variant="primary"
                    className={css.refreshButton}
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    {t('Log in again')}
                </Button>
            </section>
        </div>
    );
});
