import React from 'react';
import { cns } from '../../utils';
import css from './Input.module.scss';

export interface InputProps {
    id?: string;
    icon?: React.ReactNode;
    containerProps?: React.HTMLAttributes<HTMLDivElement>;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

export const Input: React.FC<InputProps> = (props: InputProps) => {
    const containerProps = props.containerProps
        ? {
              ...props.containerProps,
              className: cns(props.containerProps.className, css.inputContainer),
          }
        : {
              className: css.inputContainer,
          };
    const inputProps = props.inputProps
        ? {
              id: props.id,
              ...props.inputProps,
              className: cns(props.inputProps.className, css.input),
          }
        : {
              id: props.id,
              className: css.input,
          };
    return (
        <div {...containerProps}>
            <input {...inputProps} autoFocus />
            {props.icon}
        </div>
    );
};
