import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetBreadcrumb, WidgetId } from '../../../interfaces/internal';
import { logger } from '../../utils';
import { SubPage } from '../SubPage/SubPage';
import { WidgetProps } from '../Widget/Widget';

interface MaintenanceReportingProps extends RouteComponentProps {
    children: React.ReactNode;
    widget: WidgetProps;
}

function getTitle(widgetId: WidgetId, path: string | undefined, t: TFunction) {
    if (path) {
        if (path.startsWith(`/${widgetId}/equipment-availability`)) {
            return t('Equipment availability');
        }

        if (path.startsWith(`/${widgetId}/preventive-maintenance`)) {
            return t('Preventive maintenance');
        }

        if (path.startsWith(`/${widgetId}/response-time`)) {
            return t('Response time');
        }
    }

    logger.error(`[MaintenanceReporting] Unknown path: ${path}`);
    return t('Unknown');
}

export const MaintenanceReporting: React.FC<MaintenanceReportingProps> = (props: MaintenanceReportingProps) => {
    const { children, location, widget } = props;
    const { t } = useTranslation('MaintenanceReporting');

    const breadcrumb: WidgetBreadcrumb = {
        id: widget.id,
        title: getTitle(widget.id, location?.pathname, t),
    };

    return (
        <SubPage breadcrumb={breadcrumb} idSlug={widget.id}>
            {children}
        </SubPage>
    );
};
