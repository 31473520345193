import React from 'react';
import { KALMAR_RED } from '../../colors';
import css from './MainLoadingAnimation.module.scss';

export const MainLoadingAnimation: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234 99">
            <path
                fill={KALMAR_RED}
                className={css.loadingShip}
                d="M117,72V54H63V72H45V63l-9-9V36H18V54H9V72H0v7.82a2.84,2.84,0,0,0,.83,2L9,90v6.18A2.82,2.82,0,0,0,11.82,99H96.66a5.66,5.66,0,0,0,4-1.66L126,72Z"
            />
            <path
                fill={KALMAR_RED}
                className="loadingDock"
                d="M180,54V72H153V0h-9V72h-9V96.17A2.83,2.83,0,0,0,137.83,99h93.32A2.84,2.84,0,0,0,234,96.15V54Z"
            />
            <rect fill={KALMAR_RED} className={css.loadingCrane} x="126" width="90" height="9" />
            <rect fill={KALMAR_RED} className={css.loadingWire} x="205.94" width="1.68" height="55" />
            <rect fill={KALMAR_RED} className={css.loadingContainer} x="180" y="54" width="54" height="18" />
        </svg>
    );
};
