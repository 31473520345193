import useMediaQuery from '@mui/material/useMediaQuery';
import { navigate, RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API_DATE_FORMAT, EquipmentAvailabilityByEquipment } from '../../../interfaces/customer-api';
import { TimeKeyFormat, TimeUnit } from '../../../interfaces/internal';
import ArrowLeft from '../../assets/arrow-left.svg';
import { BREAKPOINT_MEDIUM } from '../../config';
import { useActions, useOvermindState } from '../../state';
import { EquipmentObject } from '../../state/state';
import { formatHoursAndMinutes, isFailed, isLoading } from '../../utils';
import { EquipmentIcon } from '../EquipmentIcon/EquipmentIcon';
import { Link } from '../Link/Link';
import { LoadingFailure } from '../LoadingFailure/LoadingFailure';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { Column, Table } from '../Table/Table';
import { TimeUnitSelector } from '../TimeUnitSelector/TimeUnitSelector';
import css from './EquipmentAvailabilityByType.module.scss';

interface EquipmentAvailabilityByTypeProps extends RouteComponentProps {
    /** URL parameters set by router `/maintenance-reporting/equipment-availability/:equipmentType` */
    equipmentType?: string;
    timeUnit?: TimeUnit;
}

interface EquipmentRow {
    availability: number;
    totalDownTime: number;
    label: string;
    equipmentNumber: string;
}

function getEquipmentRows(
    equipmentById: EquipmentObject,
    current?: EquipmentAvailabilityByEquipment | null,
): EquipmentRow[] {
    if (!current) {
        return [];
    }

    return Object.entries(current).map(([equipmentId, availability]) => {
        const equ = equipmentById[equipmentId];
        return {
            availability: availability.average * 100,
            totalDownTime: availability.totalDownTime,
            label: equipmentId,
            equipmentNumber: equ ? equ.equipment_number : '',
        };
    });
}

export const EquipmentAvailabilityByType: React.FC<EquipmentAvailabilityByTypeProps> = ({
    equipmentType,
    timeUnit,
}: EquipmentAvailabilityByTypeProps) => {
    const {
        isLoadingEquipmentAvailabilityKPIs,
        loadingStates,
        maintenanceReportingSelectedDate,
        siteEquipmentAvailabilityKPIs,
        siteEquipmentById,
    } = useOvermindState();
    const {
        handleMaintenanceReportingDateChange,
        getMaintenanceReportingKPIs,
        getEquipmentAvailabilityKPI,
    } = useActions();
    const { t } = useTranslation('EquipmentAvailabilityByType');
    const isMobile = useMediaQuery(BREAKPOINT_MEDIUM);

    const validTimeUnits = ['month', 'quarter', 'year'];
    if (timeUnit === undefined || !validTimeUnits.includes(timeUnit)) {
        navigate(`../month/${equipmentType}`, { replace: true });
        return null;
    }

    const keyFormat = TimeKeyFormat[timeUnit];
    const selectedTimeUnitKey = maintenanceReportingSelectedDate.toFormat(keyFormat);

    const selectedAvailability = siteEquipmentAvailabilityKPIs[selectedTimeUnitKey];

    const availabilityByEquipment = selectedAvailability?.byType
        ? selectedAvailability.byType[equipmentType || '']?.byEquipment
        : null;

    if (!selectedAvailability && !isLoading(loadingStates.equipmentAvailabilityKPIs[selectedTimeUnitKey])) {
        getEquipmentAvailabilityKPI({
            startDate: maintenanceReportingSelectedDate.startOf(timeUnit).toFormat(API_DATE_FORMAT),
            endDate: maintenanceReportingSelectedDate.endOf(timeUnit).toFormat(API_DATE_FORMAT),
            keyFormat,
        });
    }

    const equipment = getEquipmentRows(siteEquipmentById, availabilityByEquipment);

    const columns: Column<EquipmentRow>[] = [
        {
            title: t('Name'),
            accessor: ({ label, equipmentNumber }: EquipmentRow) => (
                <Link to={`/my-equipment/${equipmentNumber}`}>{label}</Link>
            ),
            className: css.name,
        },
        {
            title: t('Time spent'),
            accessor: ({ totalDownTime }: EquipmentRow) => formatHoursAndMinutes(totalDownTime),
            className: css.timeSpent,
        },
        {
            title: t('Availability'),
            accessor: ({ availability }: EquipmentRow) => (
                <div className={css.availabilityContainer}>
                    {!isMobile && <ProgressBar progress={availability} className={css.progressBar} />}
                    <span className={css.percentage}>{`${availability.toFixed(2)}%`}</span>
                </div>
            ),
            className: css.availability,
        },
    ];

    if (isFailed(loadingStates.equipmentAvailabilityKPIs[selectedTimeUnitKey])) {
        return (
            <LoadingFailure
                translationKey="Loading equipment availability failed"
                retry={getMaintenanceReportingKPIs}
            />
        );
    }

    return (
        <div className={css.equipmentAvailability} id="equipment-availability-by-type">
            <header className={css.header}>
                <Link id="back-to-equipment-availability-button" to="/maintenance-reporting/equipment-availability/">
                    <ArrowLeft />
                </Link>
                <h2>
                    {equipmentType} {equipmentType && <EquipmentIcon type={equipmentType} />}
                </h2>
            </header>
            <Table
                columns={columns}
                data={equipment}
                noRowsMessage={t('No data for this time period')}
                className={css.equipmentAvailabilityTable}
                isLoading={isLoadingEquipmentAvailabilityKPIs}
            />
            <TimeUnitSelector
                handleChange={handleMaintenanceReportingDateChange}
                selectedDate={maintenanceReportingSelectedDate}
                timeUnit={timeUnit}
            />
        </div>
    );
};
