import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CaseUpdatePayload } from '../../../interfaces/realtime-api';
import CaseIcon from '../../assets/case.svg';
import { useOvermindState } from '../../state';
import { getCaseManagementLink } from '../../utils';
import { Link } from '../Link/Link';
import { Transition } from '../Transition/Transition';
import { WidgetSubHeading } from '../WidgetSubHeading/WidgetSubHeading';
import css from './MyCasesWidget.module.scss';

// This is a hack fix for this issue: https://github.com/i18next/react-i18next/issues/1483
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TranslationInterpolation = any;

const CaseUpdate: React.FC<CaseUpdatePayload> = (props: CaseUpdatePayload) => {
    const { t } = useTranslation('MyCasesWidget');
    const { casesById } = useOvermindState();

    const { field, caseId } = props;
    const updatedCase = casesById[caseId];

    const fieldName = typeof field === 'string' ? t(field) : t('An unknown value');
    const oldValue = props.oldValue ?? t('n/a');
    const newValue = props.newValue ?? t('n/a');

    return (
        <Transition animation={css.slideCaseIn} htmlTagName="li" className={css.caseUpdate}>
            <div className={css.caseUpdateDescription}>
                {field === 'Comment' ? (
                    <>
                        <strong>{t('New comment')}:</strong> {newValue}
                    </>
                ) : (
                    <Trans i18nKey="caseUpdateFieldChange" t={t} parent="p">
                        {{ fieldName }} changed from <strong>{{ oldValue } as TranslationInterpolation}</strong> to
                        <strong>{{ newValue } as TranslationInterpolation}</strong>
                    </Trans>
                )}
            </div>
            {updatedCase && (
                <Link className={css.caseSubject} to={getCaseManagementLink(`/s/case/${caseId}`)}>
                    <CaseIcon className={css.caseIcon} />
                    {updatedCase.Subject}
                </Link>
            )}
        </Transition>
    );
};

export const MyCasesWidget: React.FC = () => {
    const { caseUpdates } = useOvermindState();
    const { t } = useTranslation('MyCasesWidget');
    return (
        <div className={css.myCasesWidget}>
            <WidgetSubHeading title={`${t('Recent updates')}:`} className={css.subHeading} />
            {!!caseUpdates.length && (
                <ul className={css.caseUpdateList}>
                    {caseUpdates.map((caseUpdate) => (
                        <CaseUpdate
                            {...caseUpdate}
                            key={`${caseUpdate.caseId}-${caseUpdate.field}-${caseUpdate.changedAt}`}
                        />
                    ))}
                </ul>
            )}
            {!caseUpdates.length && <p className={css.noUpdates}>{t('No recent updates to cases')}</p>}
        </div>
    );
};
