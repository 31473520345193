import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { WidgetBreadcrumb } from '../../../interfaces/internal';
import { MaintenanceCalendar } from '../MaintenanceCalendar/MaintenanceCalendar';
import { SubPage } from '../SubPage/SubPage';
import { WidgetProps } from '../Widget/Widget';
import css from './MaintenanceSchedule.module.scss';

interface MaintenanceScheduleProps extends RouteComponentProps {
    widget: WidgetProps;
}

export const MaintenanceSchedule: React.FC<MaintenanceScheduleProps> = ({ widget }: MaintenanceScheduleProps) => {
    const breadcrumb: WidgetBreadcrumb = {
        id: widget.id,
        title: widget.title,
    };

    return (
        <SubPage breadcrumb={breadcrumb} idSlug={widget.id}>
            <div className={css.maintenanceSchedule}>
                <MaintenanceCalendar />
            </div>
        </SubPage>
    );
};
